import { ReactComponent as CheckMark } from 'assets/icons/checkmark2.svg';
import { ReactComponent as Diploma } from 'assets/icons/diploma.svg';
import { ReactComponent as Education } from 'assets/icons/education.svg';
import { ReactComponent as Inspection } from 'assets/icons/inspection.svg';
// import { ReactComponent as FingerPrint } from 'assets/icons/fingerprint.svg';
import desktopImage from 'assets/images/enmedify-ui.png';
import ipadImage from 'assets/images/enmedify-ui-ipad.png';
import mobileImage from 'assets/images/enmedify-ui-mobile.png';
import quoteIcon from 'assets/images/quote-icon.svg';
import topGraphics from 'assets/images/top-pattern.svg';
import bottomGraphics from 'assets/images/bottom-pattern.svg';

const Step2 = () => (
  <div className="flex flex-col md:flex-row lg:flex-col w-full">
    <div className="w-full px-6 pt-12 pb-12 md:p-14 lg:pt-32 lg:px-16 lg:pb-8">
      <img
        src={topGraphics}
        alt=""
        className="absolute top-0 right-0 w-[70%] md:w-[30%] lg:w-[80%]"
      />
      <div className="relative z-10">
        <h3 className="text-xl md:text-2xl text-[#94A3B8] mb-6">Digitale Therapiebegleitung</h3>
        <ul>
          <li className="flex items-center mb-4">
            <CheckMark className="shrink-0 mr-2" />
            <span className="text-sm md:text-base text-[#94A3B8] font-radikal">
              Online Terminbuchung
            </span>
          </li>
          <li className="flex items-center mb-4">
            <CheckMark className="shrink-0 mr-2" />
            <span className="text-sm md:text-base text-[#94A3B8] font-radikal">
              Video-Sprechstunde
            </span>
          </li>
          <li className="flex items-center mb-4">
            <CheckMark className="shrink-0 mr-2" />
            <span className="text-sm md:text-base text-[#94A3B8] font-radikal">
              Dokumentenverwaltung
            </span>
          </li>
        </ul>
      </div>
    </div>
    <>
      <img
        src={desktopImage}
        alt=""
        className="lg:ml-16 lg:mb-16 hidden lg:block max-w-full relative z-10 object-contain"
      />
      <img
        src={ipadImage}
        alt=""
        className="hidden md:block lg:hidden max-w-[40%] mt-12 relative z-10 object-contain self-end"
      />
      <img
        src={mobileImage}
        alt=""
        className="md:hidden max-w-[60%] relative z-10 object-contain self-end"
      />
      <img
        src={bottomGraphics}
        alt=""
        className="absolute bottom-0 left-0 w-[40%] md:w-[30%] lg:w-full"
      />
    </>
    {/* <div className="flex items-center justify-end mr-8">
    TODO: uncomment once the new content page exists
      <span className="text-[#94A3B8]">
        Ihre Daten sind bei uns sicher!{' '}
        <a href="" className="text-swopa-accent-green underline mr-2">
          Mehr erfahren
        </a>
      </span>
      <FingerPrint />
    </div> */}
  </div>
);

const Step1 = () => (
  <div className="px-6 py-20 lg:py-0 md:px-16 w-full">
    <img
      src={topGraphics}
      alt=""
      className="absolute top-0 right-0 w-[70%] md:w-[30%] lg:w-[80%]"
    />
    <div className="relative">
      <img
        src={quoteIcon}
        alt=""
        className="absolute max-w-[100px] lg:max-w-none top-0 left-0 lg:-top-8 lg:-left-4"
      />
      <span className="text-[#94A3B8] text-base leading-7 md:text-xl md:leading-8 lg:text-2xl lg:leading-10 lg:max-w-[345px] font-italic block z-10 relative mb-10">
        Ich nutze Medizinalcannabis- /blüten zur Linderung von Schmerzen und aufgrund der
        entzündungs&shy;hemmenden Wirkung und kann hierdurch gänzlich auf andere Medikamente
        verzichten.
      </span>
    </div>
    <div className="flex relative z-10">
      <div className="flex flex-col">
        <span className="text-[#94A3B8] text-sm font-radikal">Andre, 27</span>
        <span className="text-[#94A3B8] text-sm font-radikal">
          Cannabispatient mit Morbus Crohn{' '}
        </span>
      </div>
    </div>
    <img
      src={bottomGraphics}
      alt=""
      className="absolute bottom-0 left-0 w-[40%] md:w-[30%] lg:w-full"
    />
  </div>
);

const Step3 = () => (
  <div className="py-12 px-6 md:px-12 w-full flex flex-col md:flex-row lg:flex-col md:justify-between">
    <img
      src={topGraphics}
      alt=""
      className="absolute top-0 right-0 w-[70%] md:w-[30%] lg:w-[80%]"
    />
    <h3 className="text-3xl md:text-4xl mr-8 leading text-[#94A3B8] relative mb-8 lg:mb-12">
      Unsere Kompetenz:
      <br /> Ihre Gesundheit
    </h3>
    <div className="flex relative z-10 md:max-w-[50%] lg:max-w-none">
      <ul className="flex flex-col w-full">
        <li className="flex border border-dashed rounded p-4 mb-4">
          <Diploma className="mr-3 flex-shrink-0" />
          <span className="text-[#94A3B8] text-base font-radikal">
            Jeder Arzt ist ein erfahrener Experte
          </span>
        </li>
        <li className="flex border border-dashed rounded p-4 mb-4">
          <Education className="mr-3 flex-shrink-0" />
          <span className="text-[#94A3B8] text-base font-radikal">
            Durch ständige Fortbildung sind unsere Ärzte immer auf dem neuesten Stand
          </span>
        </li>
        <li className="flex border border-dashed rounded p-4">
          <Inspection className="mr-3 flex-shrink-0" />
          <span className="text-[#94A3B8] text-base font-radikal">
            Regelmäßige Patientenbefragungen gewährleisten einen guten Service
          </span>
        </li>
      </ul>
    </div>
    <img
      src={bottomGraphics}
      alt=""
      className="absolute bottom-0 left-0 w-[40%] md:w-[30%] lg:w-full"
    />
  </div>
);

export default function Sidebar() {
  const URL = window.location.pathname;
  return (
    <div className="bg-swopa-primary-dark-blue flex flex-row lg:flex-col lg:w-[30%] justify-center relative h-auto lg:h-screen overflow-y-hidden">
      {(URL === '/register' || URL === '/register-v2') && <Step1 />}
      {URL === '/register/personal' && <Step2 />}
      {URL === '/register/email' && <Step3 />}
    </div>
  );
}
