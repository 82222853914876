import usePatient from 'components/Navigation/usePatient';
import { useNotificationContext } from 'components/Notification';
import http from 'components/util/http';
import { Form, Formik, FormikErrors } from 'formik';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Button from 'styles/Button';
import Input from 'styles/FormikInput';
import Toggle from 'styles/Toggle';

interface GeneralDataFormValues {
  firstName: string;
  lastName: string;
  street: string;
  postal: string;
  city: string;
  phone: string;
  deliveryFirstName?: string;
  deliveryLastName?: string;
  deliveryStreet?: string;
  deliveryPostal?: string;
  deliveryCity?: string;
}

const MANDATORY_INFO = ['firstName', 'lastName', 'street', 'postal', 'city', 'phone'];

const MANDATORY_INFO_EXTENDED = [
  'deliveryFirstName',
  'deliveryLastName',
  'deliveryStreet',
  'deliveryPostal',
  'deliveryCity',
];

interface IGeneralDataForm {
  buttonLabel?: string;
  isBookingAppointment?: boolean;
  appointmentUrl?: string;
}

export default function GeneralDataForm({
  buttonLabel,
  isBookingAppointment,
  appointmentUrl,
}: IGeneralDataForm) {
  const { data: patient } = usePatient();
  const queryClient = useQueryClient();
  const { showNotification } = useNotificationContext();
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const navigate = useNavigate();

  if (!patient) {
    return <></>;
  }

  return (
    <Formik<GeneralDataFormValues>
      validateOnChange={false}
      initialValues={{
        firstName: patient.first_name || '',
        lastName: patient.last_name || '',
        street: patient.street || '',
        postal: patient.postal_code || '',
        city: patient.city || '',
        phone: patient.phone_number || '',
        deliveryFirstName: patient.billing_first_name,
        deliveryLastName: patient.billing_last_name,
        deliveryCity: patient.billing_city,
        deliveryPostal: patient.billing_postal_code,
        deliveryStreet: patient.billing_street,
      }}
      validate={(formData) => {
        let errors: FormikErrors<GeneralDataFormValues> = {};
        MANDATORY_INFO.forEach((key: string) => {
          if (!formData[key as keyof GeneralDataFormValues]) {
            errors[key as keyof GeneralDataFormValues] = ' ';
          }
        });
        if (showDeliveryAddress) {
          MANDATORY_INFO_EXTENDED.forEach((key: string) => {
            if (!formData[key as keyof GeneralDataFormValues]) {
              errors[key as keyof GeneralDataFormValues] = ' ';
            }
          });
        }
        return errors;
      }}
      onSubmit={async (formData, { setSubmitting }) => {
        let data: any = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          street: formData.street,
          postal_code: formData.postal,
          city: formData.city,
          phone_number: formData.phone,
          billing_city: formData.deliveryCity,
          billing_first_name: formData.deliveryFirstName,
          billing_last_name: formData.deliveryLastName,
          billing_postal_code: formData.deliveryPostal,
          billing_street: formData.deliveryStreet,
          billing_address_is_different: showDeliveryAddress,
        };

        const res = await http.patch(`/medical/patients/me/`, data);
        if (res) {
          showNotification(
            {
              type: 'success',
              text: 'Daten erfolgreich gespeichert',
            },
            3000,
          );
          queryClient.invalidateQueries({
            queryKey: ['patient'],
          });
          if (isBookingAppointment) navigate(`/termin?${appointmentUrl}`);
        }

        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <h3 className="mb-2">Name</h3>
          <div className="grid mb-6 grid-cols-2 gap-2">
            <Input
              error={!!(formikProps.touched.firstName && formikProps.errors.firstName)}
              id="firstName"
              name="firstName"
              placeholder="Vorname"
            />
            <Input
              error={!!(formikProps.touched.lastName && formikProps.errors.lastName)}
              id="lastName"
              name="lastName"
              placeholder="Nachname"
            />
          </div>
          <h3 className="mb-2">Anschrift/Lieferadresse</h3>
          <Input
            error={!!(formikProps.touched.street && formikProps.errors.street)}
            id="street"
            name="street"
            placeholder="Straße und Hausnummer"
            className="mb-2"
          />
          <div className="grid mb-4 grid-cols-2 gap-2">
            <Input
              error={!!(formikProps.touched.postal && formikProps.errors.postal)}
              id="postal"
              name="postal"
              placeholder="PLZ"
            />
            <Input
              error={!!(formikProps.touched.city && formikProps.errors.city)}
              id="city"
              name="city"
              placeholder="Stadt"
            />
          </div>
          <div className="flex items-center space-x-2 mb-6">
            <Toggle
              startState={patient.billing_address_is_different}
              onChange={(val) => {
                if (!val) {
                  formikProps.values.deliveryFirstName = '';
                  formikProps.values.deliveryLastName = '';
                  formikProps.values.deliveryPostal = '';
                  formikProps.values.deliveryCity = '';
                  formikProps.values.deliveryStreet = '';
                }
                setShowDeliveryAddress(val);
              }}
            />
            <span className="text-swopa-secondary-grey text-h3">Rechnungsadresse abweichend</span>
          </div>
          {showDeliveryAddress && (
            <>
              <h3 className="mb-2">Rechnungsadresse</h3>
              <div className="grid mb-2 grid-cols-2 gap-2">
                <Input
                  error={!!formikProps.errors.deliveryFirstName}
                  id="deliveryFirstName"
                  name="deliveryFirstName"
                  placeholder="Vorname"
                />
                <Input
                  error={!!formikProps.errors.deliveryLastName}
                  id="deliveryLastName"
                  name="deliveryLastName"
                  placeholder="Nachname"
                />
              </div>
              <Input
                error={!!formikProps.errors.deliveryStreet}
                id="deliveryStreet"
                name="deliveryStreet"
                placeholder="Straße und Hausnummer"
                className="mb-2"
              />
              <div className="grid mb-6 grid-cols-2 gap-2">
                <Input
                  error={!!formikProps.errors.deliveryPostal}
                  id="deliveryPostal"
                  name="deliveryPostal"
                  placeholder="PLZ"
                />
                <Input
                  error={!!formikProps.errors.deliveryCity}
                  id="deliveryCity"
                  name="deliveryCity"
                  placeholder="Stadt"
                />
              </div>
            </>
          )}
          <h3 className="mb-2">Kontakt</h3>
          <Input
            error={!!(formikProps.touched.phone && formikProps.errors.phone)}
            id="phone"
            name="phone"
            placeholder="+49 0123456789"
            className="mb-2"
          />
          <span className="text-swopa-secondary-grey text-h3">
            Die Angabe Ihrer Telefonnummer ist wichtig, um Sie in besonderen Fällen (z.B. bei
            Terminausfall) erreichen zu können.
          </span>
          <div className="mt-6">
            <Button type="submit" disabled={formikProps.isSubmitting}>
              {buttonLabel || 'Speichern'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
