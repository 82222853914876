//import { ReactComponent as MenuDots } from 'assets/icons/menu_dots.svg';
import { ReactComponent as PrescriptionCalendarIcon } from 'assets/icons/prescription_calendar.svg';
import usePatient from 'components/Navigation/usePatient';
import { useMemo } from 'react';
import AppointmentWrapper from './AppointmentWrapper';
import StepFailure from './PrescriptionSteps/StepFailure';
import PrescriptionType from './PrescriptionType';
import usePrescriptions from './usePrescriptions';
import { addDays } from 'date-fns';

interface MyPrescriptionsProps {
  title: string;
  history?: boolean;
}

const shortUnitNames = (val: string) => {
  switch (val) {
    case 'gramm':
      return 'g';
    case 'milliliter':
      return 'ml';
    case 'piece':
      return 'Stück';
    default:
      return 'g';
  }
};

const formatPrescription = (val: string) => {
  switch (val) {
    case 'gramm':
      return '%';
    case 'milliliter':
      return 'mg/ml';
    case 'piece':
      return 'mg/Stück';
    default:
      return '%';
  }
};

function PrescriptionPlaceholder() {
  return (
    <div className="flex flex-col justify-center items-center my-6 space-y-1">
      <PrescriptionCalendarIcon />
      <span className="text-swopa-secondary-grey">
        Hier finden Sie zukünftig Ihre Verschreibungen.
      </span>
    </div>
  );
}

export default function MyPrescriptions({ history }: MyPrescriptionsProps) {
  const { data } = usePatient();
  const { data: prescriptions } = usePrescriptions();

  const finalPrescriptionList = useMemo(() => {
    if (!data) return <></>;
    if ((!prescriptions || !prescriptions.length) && !history) {
      return (
        <PrescriptionType
          precheck={{ ready: data.pre_assessment === 'positive' }}
          appointment={{ ready: data.pre_assessment === 'positive' }}
          prescriptions={[{ ready: false }]}
          startOpen
        />
      );
    }

    if (!prescriptions) return <></>;

    // Sorting should be different based on context,
    // in the overview of upcoming appointments the one closest to the current Date should be on top
    // in the history however, the sorting should be reversed and the oldest Date should be at the bottom
    // first everything is sorted, because it's necessary to figure out which appointment was the very first ever ("Erstverschreibung")
    const sortedPrescriptions = [...prescriptions].sort(
      (a, b) =>
        new Date(b.appointment.created_at).getTime() - new Date(a.appointment.created_at).getTime(),
    );

    const filteredPrescriptions = sortedPrescriptions
      .filter(({ appointment: { created_at } }) =>
        history
          ? new Date().getTime() > addDays(new Date(created_at), 8).getTime()
          : new Date().getTime() <= addDays(new Date(created_at), 8).getTime(),
      )
      .sort((a, b) => {
        if (history) {
          return (
            new Date(b.appointment.created_at).getTime() -
            new Date(a.appointment.created_at).getTime()
          );
        }

        return (
          new Date(a.appointment.created_at).getTime() -
          new Date(b.appointment.created_at).getTime()
        );
      });

    const res = filteredPrescriptions.map(({ appointment, prescription }, index) => {
      if (appointment.state === 'cancelled') return null;
      return (
        <PrescriptionType
          key={index}
          precheck={index === 0 ? { ready: true } : undefined}
          appointmentType={index === 0 ? 'first' : 'followup'}
          appointment={{
            ready: true,
            appointmentProps: {
              state: appointment.state,
              format: appointment.format,
              date: new Date(appointment.created_at),
              doctor:
                appointment.doctor && typeof appointment.doctor !== 'number'
                  ? `${
                      appointment.doctor.occupational_title
                        ? appointment.doctor.occupational_title
                        : ''
                    } ${appointment.doctor.first_name} ${appointment.doctor.last_name}`
                  : 'Doktor wird zugewiesen',
              location:
                appointment.format === 'video_consultation' ? (
                  <span>Online-Sprechstunde</span>
                ) : (
                  <>
                    {appointment.practice === null ? null : (
                      <span>
                        {appointment.practice.street} <br />
                        {appointment.practice.city} {appointment.practice.postal_code}
                      </span>
                    )}
                  </>
                ),
              video_url: appointment.video_consultation_patient_url,
              tan: appointment.video_consultation_tan,
              modify_url: appointment.modify_url,
              cancel_url: appointment.cancel_url,
            },
          }}
          prescriptions={prescription?.map((item: any) => ({
            ready:
              appointment.therapy_state === 'denied' ||
              (appointment.therapy_state === 'started_continued' && !!item),
            prescriptionProps:
              appointment.therapy_state === 'started_continued' && item
                ? {
                    //Medicine key not yet confirmed
                    medicine: item.title,
                    amount:
                      item.amount +
                      ' ' +
                      shortUnitNames(item.unit) +
                      ' ' +
                      item.pharmaceutical_type,
                    date: new Date(item.date),
                    dosage: item.procedure,
                    indica: item.indica_sativa || undefined,
                    proportion: item.share_of_cbd
                      ? `${item.share_of_thc} ${formatPrescription(item.unit)} / ${
                          item.share_of_cbd
                        } ${formatPrescription(item.unit)}`
                      : undefined,
                  }
                : undefined,
          }))}
          startOpen
        />
      );
    });

    if (res.length) {
      return res;
    } else {
      return <PrescriptionPlaceholder />;
    }
  }, [data, prescriptions, history]);

  //TODO: Handle loading
  if (!data || data.eligible_for_treatment === 'no') {
    return <></>;
  }

  return (
    <>
      {data.eligible_for_treatment === 'maybe' ? (
        <PrescriptionPlaceholder />
      ) : (
        <div className="my-4 grid gap-2">
          {data.pre_assessment === 'negative' ? (
            <AppointmentWrapper
              title="Ersttermin"
              steps={[
                <StepFailure
                  text="Wir können Ihnen leider keinen Termin anbieten."
                  link="/service"
                />,
              ]}
              startOpen
            />
          ) : (
            <>{finalPrescriptionList}</>
          )}
        </div>
      )}
    </>
  );
}
