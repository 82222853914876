import { CookieConsentConfig } from 'vanilla-cookieconsent';
import de from './translations/de';
import en from './translations/en';

const consentConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: 'cloud',
      position: 'bottom center',
      equalWeightButtons: false,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'bar wide',
      position: 'left',
      equalWeightButtons: false,
      flipButtons: false,
    },
  },
  cookie: {
    name: 'cc',
    domain: new URL(process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL || '').hostname.replace(
      'www.',
      '',
    ),
    path: '/',
    expiresAfterDays: 365,
    sameSite: 'Lax',
    useLocalStorage: false,
  },
  manageScriptTags: false,
  disablePageInteraction: false,
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    functionality: {},
    analytics: {},
    personalization: {},
    marketing: {},
  },
  language: {
    default: 'de',
    autoDetect: undefined,
    translations: {
      en,
      de,
    },
  },
};

export default consentConfig;
