import { Formik, Form } from 'formik';
import Input from 'styles/FormikInput';
import { string, object } from 'yup';
import Button from 'styles/Button';
import http from 'components/util/http';
import { useNotificationContext } from 'components/Notification';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Title from 'components/util/Title';
import Layout from './Layout';

type ForgotPasswordFormValues = {
  email: string;
};

const validationSchema = object({
  email: string()
    .required('Bitte geben Sie ihre E-Mail-Adresse ein')
    .email('Ungültige E-Mail-Adresse'),
});

export default function ResendActivationEmail() {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const { showNotification, clearNotification } = useNotificationContext();

  if (success) {
    return (
      <Layout>
        <Title title="Aktivierungs-E-Mail senden" />
        <h2 className="mb-2 whitespace-nowrap">Aktivierungs-E-Mail senden</h2>
        <p className="text-swopa-primary-dark-blue mb-6">
          Wenn für die angegebene E-Mail-Adresse ein Konto registriert wurde, sollten Sie in wenigen
          Minuten eine Aktivierungs-E-Mail erhalten. Falls diese nicht in Ihrem Posteingang zu
          finden ist, überprüfen Sie bitte auch den Spam-Ordner.
        </p>
        <Button onClick={() => navigate('/login')}>ZUR ANMELDUNG</Button>
      </Layout>
    );
  }

  return (
    <Layout>
      <Title title="Aktivierungs-E-Mail senden" />
      <h2 className="mb-2 whitespace-nowrap">Aktivierungs-E-Mail senden</h2>
      <span className="text-swopa-primary-dark-blue mb-6">
        Teilen Sie uns Ihre E-Mail-Adresse mit. Wir werden die Aktivierungs-E-Mail erneut senden.
      </span>
      <Formik<ForgotPasswordFormValues>
        onSubmit={(formData) => {
          clearNotification();

          http
            .post('/auth/users/resend-activation/', { email: formData.email })
            .then(() => setSuccess(true))
            .catch(() => {
              showNotification({ type: 'error', text: 'Etwas ist schiefgelaufen.' });
            });
        }}
        validationSchema={validationSchema}
        initialValues={{ email: '' }}
      >
        {({ touched, errors, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="E-Mail-Adresse"
              error={!!(touched.email && errors.email)}
              errorMessage={errors.email}
              className="mb-4"
            />
            <Button type="submit" disabled={isSubmitting}>
              E-Mail senden
            </Button>
          </Form>
        )}
      </Formik>
      <span
        className="text-swopa-secondary-grey cursor-pointer mt-2 hover:text-swopa-accent-green"
        onClick={() => navigate('/login')}
      >
        Zurück zur Anmeldung
      </span>
    </Layout>
  );
}
