import classNames from 'classnames';
import { ReactComponent as TimerIcon } from 'assets/icons/timer.svg';
import { ReactComponent as CheckMark } from 'assets/icons/check.svg';
import CallToAction from './CallToAction';

type StepProps = {
  step: number;
  status: string;
  title: string;
  description: string;
  tooltip?: string;
  ctaTitle: string;
  ctaDescription: string;
  theme: string;
  activeStatus: string;
};

const Icon = ({ status, step }: { status: string; step: number }) => {
  if (status === 'evaluating') {
    return <TimerIcon className="mr-3 flex-shrink-0" />;
  }
  if (status === 'completed') {
    if (step === 2) {
      return (
        <span
          className={classNames(
            'w-6 h-6 rounded-full flex items-center justify-center font-radikal mr-3 border border-swopa-accent-green bg-swopa-accent-green text-white',
          )}
        >
          {step}
        </span>
      );
    }
    return (
      <span>
        <CheckMark className="w-6 h-6 mr-3 text-swopa-accent-green flex-shrink-0" />
      </span>
    );
  }
  return (
    <span
      className={classNames(
        'w-6 h-6 rounded-full flex flex-shrink-0 items-center justify-center font-radikal mr-3 border border-swopa-accent-green text-swopa-accent-green',
        { 'bg-swopa-accent-green !text-white': status === 'active' },
      )}
    >
      {step}
    </span>
  );
};

export default function Step({ data }: { data: StepProps }) {
  const active = data.activeStatus === 'active';
  const evaluating = data.activeStatus === 'evaluating';
  const completed = data.activeStatus === 'completed';

  return (
    <>
      <li
        className={classNames('flex p-2 pr-4 rounded relative last-of-type:mb-0', {
          'bg-swopa-hover-grey mb-2': active || evaluating,
        })}
      >
        <Icon status={data.activeStatus} step={data.step} />
        <div>
          <span
            className={classNames('text-swopa-primary-dark-blue block', {
              'font-radikal text-base': active || evaluating || completed,
              'text-swopa-accent-green': completed,
            })}
          >
            {data.title}
          </span>
          {(active || evaluating) && (
            <p className="text-swopa-primary-dark-blue max-w-md">{data.description}</p>
          )}
        </div>
      </li>
      {/* show on mobile */}
      {(active || evaluating) && (
        <CallToAction data={data} status={data.status} className="flex md:hidden" />
      )}
    </>
  );
}
