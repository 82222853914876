import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useField } from 'formik';
import { Fragment } from 'react';

type Option = {
  label: string;
  value: any;
};

type FormikSelectProps = {
  label?: string;
  options: Option[];
  name: string;
};

export default function FormikSelect({ label, options, name }: FormikSelectProps) {
  const [_field, meta, helpers] = useField(name);

  const { value, error } = meta;
  const { setValue } = helpers;

  const selectedOption = options.find((option) => option.value === value);

  return (
    <Listbox value={value} onChange={setValue}>
      <div className="relative mt-1">
        {label && (
          <Listbox.Label
            className={classNames('block text-sm font-medium', {
              'text-swopa-warning-red': error,
              'text-swopa-primary-dark-blue': !error,
            })}
          >
            {label}:
          </Listbox.Label>
        )}
        <Listbox.Button
          className={classNames(
            'relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-swopa-primary-dark-blue active:outline-swopa-primary-dark-blue',
            {
              'text-swopa-secondary-grey': !selectedOption,
              'outline outline-swopa-warning-red': error,
            },
          )}
        >
          {selectedOption?.label || 'Bitte wählen'}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none z-10">
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                value={option.value}
                className={({ active }) =>
                  `relative cursor-default select-none py-1 pl-5 pr-4 ${
                    active ? 'bg-swopa-secondary-light-blue-hover' : 'text-gray-900'
                  }`
                }
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
