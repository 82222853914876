import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Cookies from 'js-cookie';

import de_flag from '../../../assets/images/flags/DE.svg';
import ch_flag from '../../../assets/images/flags/CH.svg';
import { CountryCode } from "localizations/types";

type UserCountryVerification = 'correct' | 'incorrect' | 'other';
type UserCountry = CountryCode | 'other' | 'correct';
type Countries = {
  [key in CountryCode]: Country;
}

type CountrySelect = {
  redirect: (country: CountryCode) => boolean;
  verifyUserCountry: () => Promise<UserCountryVerification>;
  getCountries: () => Countries;
  getAppCountry: () => Country;
  checkRedirection: () => boolean;
}

export type Country = {
  code: CountryCode;
  name: string;
  icon: string;
  url: string;
};

const CookieCountryCode = 'country_code';

const EmptyCountry: Country = {
  code: 'DE',
  name: '',
  icon: '',
  url: '',
}

const useCountrySelect = (): CountrySelect => {
  const [availableCountries, setAvailableCountries] = useState<Countries>({ DE: EmptyCountry, CH: EmptyCountry });
  const [redirectCountryCode, setRedirectCountryCode] = useState<CountryCode | undefined>(undefined);

  const redirect = useCallback((countryCode: CountryCode) => {
    Cookies.set(CookieCountryCode, countryCode);
    const country = availableCountries[countryCode];
    if(country && country.url){
      window.location.href = `${country.url}/?redirected`;
      return true;
    } else {
      return false;
    }
  }, [availableCountries]);

  useEffect(() => {
    if(process.env.REACT_APP_URLS !== undefined) {
      const appUrls = JSON.parse(process.env.REACT_APP_URLS || "");
      setAvailableCountries({
        CH: {
          code: 'CH',
          name: 'Schweiz',
          icon: ch_flag,
          url: appUrls['CH'],
        },
        DE: {
          code: 'DE',
          name: 'Deutschland',
          icon: de_flag,
          url: appUrls['DE'],
        }
      });

      if(redirectCountryCode){
        redirect(redirectCountryCode);
      }
    }
  }, [redirectCountryCode]);


  const getUserCountry = async (): Promise<UserCountry> => {
    try{
      const response = await axios.get(`https://ipinfo.io?token=${process.env.REACT_APP_IPINFO_TOKEN}`);
      const countryName = response.data.country;

      if(countryName == 'DE' || countryName == 'CH') {
        return countryName;
      } else {
        return 'other';
      }
    } catch(error){
      console.error('Cannot get user location, assume it is correct. ', error);
      //cannot get user location, so assume it is correct to allow user using services
      return 'correct';
    }
  }

  const verifyUserCountry = async (): Promise<UserCountryVerification> => {
    const appCountryCode = process.env.REACT_APP_COUNTRY_CODE;
    const userCountry = await getUserCountry();
    const selectedCountry = Cookies.get(CookieCountryCode) as CountryCode;

    if(userCountry === 'correct'){
      return 'correct';
    }
    if(userCountry === 'other') {
      return 'other';
    }
    if(selectedCountry && appCountryCode !== selectedCountry) {
      setRedirectCountryCode(selectedCountry);
      return 'correct';
    }
    if(appCountryCode === selectedCountry){
      return 'correct';
    }
    if(userCountry !== appCountryCode) {
      return 'incorrect'
    } else {
      return 'correct';
    }
  }

  const getCountries = (): Countries => {
    return availableCountries;
  }

  const getAppCountry = (): Country => {
    return availableCountries[process.env.REACT_APP_COUNTRY_CODE as CountryCode];
  }

  const checkRedirection = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('redirected')) {
      window.history.replaceState({}, document.title, window.location.pathname);
      Cookies.set(CookieCountryCode, process.env.REACT_APP_COUNTRY_CODE!);
      return true;
    }

    return false;
  }

  return {
    redirect,
    verifyUserCountry,
    getCountries,
    getAppCountry,
    checkRedirection
  }

}

export default useCountrySelect;



