import classNames from 'classnames';
import { useField } from 'formik';
import { useState } from 'react';

type FormikRangeSelectProps = {
  min: number;
  max: number;
  minLabel: string;
  maxLabel: string;
  name: string;
};
export default function FormikRangeSelect({
  min,
  max,
  minLabel,
  maxLabel,
  name,
}: FormikRangeSelectProps) {
  const [_field, meta, helpers] = useField(name);
  const [hovered, setHovered] = useState<number>();
  const { value, error } = meta;
  const { setValue } = helpers;

  return (
    <div className="md:max-w-2xl">
      <div
        className={classNames('flex justify-between px-1 mb-1', {
          'text-swopa-warning-red': error,
          'text-swopa-primary-grey': !error,
        })}
      >
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </div>
      <div className="flex space-x-2 justify-between">
        {Array.from({ length: max - min + 1 }).map((_, index) => {
          const currentValue = min + index;

          let isActive = currentValue <= value;
          if (hovered) {
            isActive = currentValue <= hovered;
          }

          return (
            <button
              type="button"
              key={index}
              className={`h-7 w-7 md:h-10 md:w-10 rounded-lg flex items-center justify-center ${
                !isActive
                  ? 'bg-swopa-primary-grey-light text-black'
                  : 'bg-swopa-primary-dark-blue text-white'
              }`}
              onMouseEnter={() => setHovered(currentValue)}
              onMouseLeave={() => setHovered(undefined)}
              onClick={() => setValue(currentValue)}
            >
              <span>{index + 1}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
