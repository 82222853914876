import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
//import { differenceInYears } from 'date-fns';
import { ChangeEvent, useState } from 'react';

import FormikInput from 'styles/Signup/FormikInput';
import FormikRadio from 'styles/Signup/FormikRadio';
import FormikDatepicker from 'styles/Signup/FormikDatepicker';
import useSessionStorage from 'hooks/useSessionStorage';
import Progress from 'components/Progress';
import { differenceInYears } from 'date-fns';
import Modal from '../../styles/Modal';
import Box from '../../styles/Box';

type FormValues = {
  gender: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
};

const validationSchema = Yup.object().shape({
  gender: Yup.string().required('Bitte geben Sie Ihr Geschlecht an'),
  first_name: Yup.string().required('Bitte geben Sie Ihren Namen ein'),
  last_name: Yup.string().required('Bitte geben Sie Ihren Nachnamen ein'),
  date_of_birth: Yup.date().required('Bitte geben Sie Ihr Alter an'),
});
export default function Personal() {
  const navigate = useNavigate();
  const { getItem, setItem } = useSessionStorage();
  const [storedUserData] = useState(getItem('personal'));
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (formValues: FormValues) => {
    setItem('personal', formValues);
    window.dataLayer.push({
      event: 'patienten-register',
      eventStatus: 'step-1',
    });
    navigate('/register/email');
  };

  const UnderageModal = () => (
    <Modal onClose={() => setShowModal(false)}>
      <Box className="p-6 max-w-lg">
        <h2 className="mb-1">Altersbeschränkung</h2>
        <div className="mb-8">
          <span className="text-swopa-primary-dark-blue">
            Vielen Dank für Ihr Interesse an unserer Behandlungen. Unsere Behandlungen richtet sich
            an Personen ab 21 Jahren.
          </span>
        </div>
        <div className="flex items-center justify-center">
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="bg-swopa-accent-green hover:bg-swopa-accent-green-hover text-swopa-primary-dark uppercase p-2 w-32 rounded mb-2 font-radikal text-sm"
          >
            Verstanden
          </button>
        </div>
      </Box>
    </Modal>
  );

  const onDateChange = (formikHandleChange: (e: ChangeEvent<any>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const inputDate = new Date(e.target.value);
      const now = new Date();

      if (differenceInYears(now, inputDate) < 21) {
        setShowModal(true);
      }

      formikHandleChange(e);
    };
  };

  return (
    <>
      <div className="max-w-lg m-auto">
        <Progress
          currentStep={0}
          steps={[
            {
              title: 'In nur wenigen Schritten zur persönlichen Therapie',
              description:
                'Erstellen Sie Ihr kostenloses Enmedify-Konto und nutzen Sie alle Vorteile unseres digitalen Therapieprozesses.',
            },
            {
              title: 'Persönliche Angaben',
              description: '',
            },
          ]}
        />
      </div>

      <Formik
        initialValues={{
          gender: storedUserData?.gender || '',
          first_name: storedUserData?.first_name || '',
          last_name: storedUserData?.last_name || '',
          date_of_birth: storedUserData?.date_of_birth || '',
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <div className="max-w-lg m-auto">
              <h3 className="text-swopa-primary-dark-blue font-radikal mb-4 text-base text-center">
                Persönliche Angaben
              </h3>
              <div className="flex mb-6 flex-col">
                <div className="flex">
                  <FormikRadio
                    type="radio"
                    name="gender"
                    id="m"
                    label="Herr"
                    value="m"
                    error={!!(formikProps.touched.gender && formikProps.errors.gender)}
                  />
                  <FormikRadio
                    type="radio"
                    name="gender"
                    id="w"
                    label="Frau"
                    value="w"
                    error={!!(formikProps.touched.gender && formikProps.errors.gender)}
                  />
                  <FormikRadio
                    type="radio"
                    name="gender"
                    id="d"
                    label="Divers"
                    value="d"
                    error={!!(formikProps.touched.gender && formikProps.errors.gender)}
                  />
                </div>
                {!!(formikProps.touched.gender && formikProps.errors.gender) && (
                  <span className="text-swopa-warning-red mt-2 text-xs">
                    {formikProps.errors.gender}
                  </span>
                )}
              </div>
              <div className="grid grid-cols-2 gap-2 mb-4">
                <FormikInput
                  label="Vorname"
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="Vorname"
                  error={!!(formikProps.touched.first_name && formikProps.errors.first_name)}
                  errorMessage={formikProps.errors.first_name}
                />
                <FormikInput
                  label="Nachname"
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Nachname"
                  error={!!(formikProps.touched.last_name && formikProps.errors.last_name)}
                  errorMessage={formikProps.errors.last_name}
                />
              </div>

              <div className="mb-16 md:mb-36">
                <FormikDatepicker
                  label="Geburtsdatum"
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                  min="1900-01-01"
                  placeholder="tt/mm/jjjj"
                  error={!!(formikProps.touched.date_of_birth && formikProps.errors.date_of_birth)}
                  errorMessage={formikProps.errors.date_of_birth}
                  onChange={onDateChange(formikProps.handleChange)}
                />
              </div>
            </div>

            <div className="md:px-6 mb-8 w-full text-center">
              <button
                className="bg-swopa-accent-green hover:bg-swopa-accent-green-hover text-swopa-primary-dark uppercase p-4 w-full flex items-center justify-center rounded mb-4 font-radikal text-sm"
                type="submit"
              >
                Weiter
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && <UnderageModal />}
    </>
  );
}
