import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotificationContext } from 'components/Notification';
import http from 'components/util/http';

export default function EmailReset() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { showNotification } = useNotificationContext();

  useEffect(() => {
    if (!params.has('uid') || !params.has('token')) {
      showNotification(
        {
          type: 'error',
          text: 'Etwas ist schiefgelaufen.',
        },
        10000,
      );
      navigate('/');
      return;
    }

    http
      .post('/auth/users/email-confirm/', { uid: params.get('uid'), token: params.get('token') })
      .then(() => {
        showNotification(
          {
            type: 'success',
            text: 'Danke, Sie haben Ihre E-Mail-Adresse erfolgreich verifiziert!',
          },
          10000,
        );
        navigate('/');
      })
      .catch(() => {
        showNotification(
          {
            type: 'error',
            text: 'Etwas ist schiefgelaufen.',
          },
          10000,
        );
        navigate('/');
      });
  }, []);

  return null;
}
