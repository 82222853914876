import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Circle from './Circle';
import {
  PREASSESSMENT_DONT_EXIST,
  PREASSESSMENT_UNDER_EVALUTATION,
  READY_TO_BOOK_FIRST_APPOINTMENT,
  SHOW_FIRST_BOOKING,
} from 'hooks/usePatientStatus';
import Button from 'styles/Button';
import { BookAppointmentButton } from '../PrescriptionSteps/Appointment';
import useLocalizedPrices from '../../../hooks/useLocalizedPrices';

export default function CallToAction({
  data,
  status,
  className,
}: {
  data: any;
  status: string;
  className?: string;
}) {
  const backgroundColor =
    data.theme === 'dark' ? 'bg-swopa-primary-dark-blue' : 'bg-swopa-hover-grey';
  const textColor = data.theme === 'dark' ? 'text-white' : 'text-swopa-primary-dark-blue';
  const { first_appointment_clinic, first_appointment_online } = useLocalizedPrices();
  return (
    <div
      className={classNames(
        'flex w-full md:w-1/2 mb-4 md:mb-0 py-8 md:min-h-[210px] rounded justify-center items-center relative overflow-hidden',
        backgroundColor,
        textColor,
        { 'shadow-md': data.theme === 'light' },
        className,
      )}
    >
      <Circle
        className={classNames('-top-[320px] -left-[150px] xl:block', {
          '!border-swopa-grey-2': data.theme === 'light',
        })}
      />
      <Circle
        className={classNames('-bottom-[250px] -right-[100px]', {
          '!border-swopa-grey-2': data.theme === 'light',
        })}
      />
      <div className="flex items-center flex-col max-w-[300px] relative z-20">
        {status === PREASSESSMENT_DONT_EXIST && (
          <a href="/fragebogen" data-elementclick="patienten-preassesment-start">
            <Button>Fragebogen starten</Button>
          </a>
        )}
        {status === PREASSESSMENT_UNDER_EVALUTATION && (
          <Link
            to="/dokumente"
            className="bg-swopa-accent-green hover:bg-swopa-accent-green-hover hover:ease-in-out duration-300 text-swopa-primary-dark-blue font-radikal uppercase py-3 px-10 rounded mb-2"
          >
            {data.ctaTitle}
          </Link>
        )}
        {status === READY_TO_BOOK_FIRST_APPOINTMENT && (
          <div className="flex flex-col gap-3">
            <BookAppointmentButton
              buttonText={`Online Termin (${first_appointment_online})`}
              appointmentUrl={data.firstAppointmentUrl.virtual}
              analyticsEvent={{
                event: 'patienten-btn-booking',
                eventStatus: 'online',
              }}
            />
            <BookAppointmentButton
              buttonText={`Termin vor Ort (${first_appointment_clinic})`}
              appointmentUrl={data.firstAppointmentUrl.inPerson}
              analyticsEvent={{
                event: 'patienten-btn-booking',
                eventStatus: 'stationary',
              }}
            />
          </div>
        )}
        {status === SHOW_FIRST_BOOKING && (
          <Link
            to="/dokumente"
            className="bg-swopa-accent-green hover:bg-swopa-accent-green-hover hover:ease-in-out duration-300 text-swopa-primary-dark-blue font-radikal uppercase py-3 px-10 rounded mb-2"
          >
            {data.ctaTitle}
          </Link>
        )}
        <span className={classNames(textColor, 'block mt-2')}>{data.ctaDescription}</span>
      </div>
    </div>
  );
}
