import { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import http, { STORAGE_KEY } from './http';
import { jwtDecode } from 'jwt-decode';

export enum LoginState {
  Loading = 1,
  LoggedIn,
  NotLoggedIn,
}

interface IUser {
  email: string;
  id: number;
  patient: number;
  managed_doctor: number;
  last_login: any;
  settings: any;
}

interface IAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  authenticatedUser: IUser | null;
  updateUser: (data: UpdateUserParams) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  autoLogin: (token: string, callback: (result: boolean) => void) => void;
  logout: () => Promise<void>;
}

const AuthContext = createContext<IAuth | null>(null);

const isTokenValid = (token: string) => {
  const decodedToken: any = jwtDecode(token);
  if (!decodedToken) return false;
  return decodedToken.exp * 1000 > new Date().getTime();
};

// Updating user data is done via PATCH,
// the same endpoint is used in multiple UI forms (Settings -> Change E-Mail and Change Password)
type UpdateUserParams =
  | {
      password: string;
      old_password: string;
    }
  | {
      email: string;
    }
  | {
      last_login: string;
    };

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const [authed, setAuthed] = useState(LoginState.Loading);
  const [user, setUser] = useState<IUser | null>(null);

  const fetchUser = () =>
    new Promise<IUser>((resolve, reject) => {
      http.get('/auth/users/me/').then(
        async (res) => {
          const data: IUser = res;
          resolve(data);
        },
        () => reject(),
      );
    });

  const updateUser = useCallback(
    (data: UpdateUserParams) =>
      http.patch('/auth/users/me/', data).then((updatedUser: IUser) => setUser(updatedUser)),
    [],
  );

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEY);

    if (!token || !isTokenValid(token)) {
      setAuthed(LoginState.NotLoggedIn);
      http.removeAuthHeader();
      return;
    }
    fetchUser().then(
      (userData) => {
        setUser(userData);
        setAuthed(LoginState.LoggedIn);
      },
      () => {
        http.removeAuthHeader();
        setAuthed(LoginState.NotLoggedIn);
      },
    );
  }, []);

  const auth = useMemo(
    () => ({
      isLoading: authed === LoginState.Loading,
      isAuthenticated: authed === LoginState.LoggedIn,
      authenticatedUser: user,
      updateUser,
      autoLogin: async (token: string, callback: (resut: boolean) => void) => {
        http.setAuthHeader(token);
        const user: IUser = await fetchUser();
        if (user.patient === null) {
          callback(false);
        }
        setUser(user);
        setAuthed(LoginState.LoggedIn);
        callback(true);
      },
      login: (email: string, password: string) => {
        return new Promise<void>((resolve, reject) => {
          http.post('/auth/jwt/create', { email, password }).then(
            async (res) => {
              const { access } = res;
              http.setAuthHeader(access);
              const user: IUser = await fetchUser();
              if (user.patient === null) {
                reject();
              }
              setUser(user);
              setAuthed(LoginState.LoggedIn);
              resolve();
            },
            () => reject(),
          );
        });
      },
      logout() {
        return new Promise<void>((resolve) => {
          setAuthed(LoginState.NotLoggedIn);
          http.removeAuthHeader();
          resolve();
        });
      },
    }),
    [authed, setAuthed, updateUser, user],
  );

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => {
  const ctx = useContext(AuthContext);
  if (ctx === null) {
    throw new Error('AuthContext missing.');
  }

  return ctx;
};
