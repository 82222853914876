import ReactStarsRating from 'react-awesome-stars-rating';
import { useState } from 'react';

type Rating = {
  key: string;
  value?: number;
  label: string;
  min: number;
  max: number;
  step: number;
};

type RatingProps = {
  ratings: Rating[];
  onChange: (ratings: Rating[]) => void;
};

export default function Rating({ ratings: initialRatings, onChange }: RatingProps) {
  const [ratings, setRatings] = useState(initialRatings);

  const changeRating = (key: string, value: number) => {
    const newRatings = ratings.map((rating) =>
      rating.key === key ? { ...rating, value } : rating,
    );
    setRatings(newRatings);
    onChange(newRatings);
  };

  const getRatingText = (rating: Rating) => {
    const { value, max } = rating;
    if (value === undefined || max === undefined) {
      return '';
    }
    const percentage = (value / max) * 100;
    if (percentage <= 20) {
      return 'Mangelhaft';
    } else if (percentage <= 40) {
      return 'Ausreichend';
    } else if (percentage <= 60) {
      return 'Befriedigend';
    } else if (percentage <= 80) {
      return 'Gut';
    }
    return 'Sehr gut';
  };

  return (
    <div className="flex flex-col space-y-5">
      {ratings.map((rating) => (
        <div key={rating.key} className="flex flex-col">
          <label
            className="text-swopa-primary-dark-blue text-base font-radikal mb-2"
            htmlFor={rating.key}
          >
            {rating.label}
          </label>
          <ReactStarsRating
            className="flex mb-1 mx-auto"
            starGap={40}
            size={40}
            id={rating.key}
            value={(rating.value || 0) / 2}
            onChange={(value: number) => changeRating(rating.key, value * 2)}
            count={rating.max / 2}
          />
          {rating.value !== undefined && <p className="text-sm">{getRatingText(rating)}</p>}
        </div>
      ))}
    </div>
  );
}
