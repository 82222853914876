import useLocalizedMarketingPageLinks from '../../hooks/useLocalizedMarketingPageLinks';
import useLocalizedImages from '../../hooks/useLocalizedImages';
import CountrySelect from 'components/Navigation/CountrySelect/CountrySelect';

export default function Header() {
  const { marketing_page } = useLocalizedMarketingPageLinks();
  const { logo } = useLocalizedImages();

  return (
    <header className="px-0 py-4 md:p-8">
      <a href={marketing_page} className="inline-block">
        <img src={logo} alt="logo" className="w-[116px]" />
      </a>
      <CountrySelect />
    </header>
  );
}
