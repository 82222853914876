import { ReactNode, createContext, useContext, useState } from 'react';

interface BookingUrlContextType {
  bookingUrl: string | null;
  setBookingUrl: (value: string | null) => void;
}

const BookingUrlContext = createContext<BookingUrlContextType | undefined>(undefined);

export const BookingUrl = ({ children }: { children: ReactNode }) => {
  const [bookingUrl, setBookingUrl] = useState<string | null>(null);

  return (
    <BookingUrlContext.Provider value={{ bookingUrl, setBookingUrl }}>
      {children}
    </BookingUrlContext.Provider>
  );
};

export const useBookingUrl = () => {
  const context = useContext(BookingUrlContext);

  if (!context) {
    throw new Error('useBookingUrl must be used within an BookingUrlContext');
  }

  return context;
};
