import { useState } from 'react';
import { object, string } from 'yup';
import { Form, Formik } from 'formik';
import Button from 'styles/Button';
import { useNotificationContext } from 'components/Notification';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { useAuthContext } from 'components/util/useAuth';
import Input from 'styles/FormikInput';

interface ChangeEmailFormValues {
  newEmail: string;
  newEmailRepeat: string;
}

const initialValues: ChangeEmailFormValues = {
  newEmail: '',
  newEmailRepeat: '',
};

const EMAIL_REQUIRED_ERROR = 'Bitte geben Sie eine E-Mail-Adresse ein.';
const EMAILS_NOT_MATCHING_STATUS =
  'Die E-Mail-Adressen stimmen nicht überein. Bitte versuchen Sie es erneut.';

const validationSchema = object({
  newEmail: string().required(EMAIL_REQUIRED_ERROR),
  newEmailRepeat: string().required(EMAIL_REQUIRED_ERROR),
});

export default function ChangeEmailForm() {
  const { authenticatedUser } = useAuthContext();

  const [showForm, setShowForm] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return (
      <div className="flex w-full flex-col justify-center items-center text-swopa-primary-dark-blue text-center h-[248px]">
        <ClockIcon className="mb-2 w-[24px] h-[24px]" />
        Wir haben Ihnen einen Link an Ihre neue E-Mail-Adresse geschickt, um diese zu bestätigen.
      </div>
    );
  }

  return (
    <>
      <input
        readOnly
        disabled
        value={authenticatedUser?.email}
        className={`appearance-none border bg-swopa-hover-grey rounded w-full py-3 px-4 text-swopa-secondary-grey  focus:outline-none mb-6`}
      />
      {showForm ? (
        <InnerForm
          onSuccess={() => {
            setShowForm(false);
            setSubmitSuccess(true);
          }}
        />
      ) : (
        <Button onClick={() => setShowForm(true)}>E-MAIL ADRESSE ÄNDERN</Button>
      )}
    </>
  );
}

interface InnerFormProps {
  onSuccess: () => void;
}

function InnerForm({ onSuccess }: InnerFormProps) {
  const { updateUser } = useAuthContext();
  const { showNotification, clearNotification } = useNotificationContext();
  return (
    <Formik<ChangeEmailFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        setSubmitting(true);
        clearNotification();
        if (formData.newEmail !== formData.newEmailRepeat) {
          showNotification({ type: 'error', text: EMAILS_NOT_MATCHING_STATUS });
          setSubmitting(false);
          return;
        }

        updateUser({
          email: formData.newEmail,
        })
          .then(onSuccess)
          .catch(() => showNotification({ type: 'error', text: 'Etwas ist schiefgelaufen.' }))
          .finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => {
        return (
          <Form onSubmit={formikProps.handleSubmit}>
            <Input
              id="newEmail"
              name="newEmail"
              placeholder="Neue E-Mail-Adresse"
              error={
                !!(formikProps.touched.newEmail && formikProps.errors.newEmail) ||
                formikProps.status === EMAILS_NOT_MATCHING_STATUS
              }
              errorMessage={
                formikProps.errors.newEmail === EMAILS_NOT_MATCHING_STATUS
                  ? undefined
                  : formikProps.errors.newEmail
              }
              className="mb-2"
            />
            <Input
              id="newEmailRepeat"
              name="newEmailRepeat"
              placeholder="Neue Adresse bestätigen"
              error={
                !!(formikProps.touched.newEmailRepeat && formikProps.errors.newEmailRepeat) ||
                formikProps.status === EMAILS_NOT_MATCHING_STATUS
              }
              errorMessage={
                formikProps.errors.newEmailRepeat === EMAILS_NOT_MATCHING_STATUS
                  ? undefined
                  : formikProps.errors.newEmailRepeat
              }
              className="mb-6"
            />
            <Button type="submit" disabled={formikProps.isSubmitting}>
              E-MAIL ADRESSE SPEICHERN
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
