import { useEffect, useRef, useState } from 'react';
import { Listbox } from '@headlessui/react';
import useCountrySelect, { Country } from './useCountrySelect';
import { IncorrectCountryDialog, OtherCountryDialog } from './CountrySelectDialogs';
import globe from '../../../assets/images/globe.svg';

type DialogType = 'incorrect_country' | 'other_country' | undefined;

export default function CountrySelect() {
  const countrySelect = useCountrySelect();
  const [dialogType, setDialogType] = useState<DialogType>();
  const [isRedirected, setIsRedirected] = useState(false);


  useEffect(() => {
    if(countrySelect.checkRedirection()){
      setIsRedirected(true);
    }

    if(!isRedirected) {
      const verifyCountry = async () => {
        const userCountry = await countrySelect.verifyUserCountry();
        if(userCountry === 'incorrect'){
          setDialogType('incorrect_country');
        }
        if(userCountry === 'other'){
          setDialogType('other_country');
        }
      };
      verifyCountry();
    }
  }, [countrySelect, isRedirected]);

  const onCountrySelected = (value: Country) => {
    countrySelect.redirect(value.code);
  };

  const renderDialog = () => {
    if(isRedirected){
      return <></>;
    }
    if(dialogType === 'incorrect_country'){
      return <IncorrectCountryDialog />;
    }
    if(dialogType === 'other_country'){
      return <OtherCountryDialog />
    }
  }

  return (
    <>
      {renderDialog()}
      <Listbox value={countrySelect.getAppCountry()} onChange={onCountrySelected}>
        <div className="relative flex w-40">
          <Listbox.Button className="relative w-full cursor-pointer rounded-md pr-3 pl-3 lg:p-0.5 lg:mt-0 text-left text-gray-900 outline-none sm:text-sm hover:text-swopa-accent-green-hover arrow-down">
            <span className="flex items-center justify-center lg:justify-start">
              <img alt="" src={globe} className="h-4 w-4" />
              <span className="ml-2 hidden lg:block">{countrySelect.getAppCountry()?.name}</span>
            </span>
          </Listbox.Button>

          <Listbox.Options className="absolute z-10 mt-7 max-h32 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
            {Object.values(countrySelect.getCountries()).map((country) => (
              <Listbox.Option
                key={country.name}
                value={country}
                className="group relative cursor-pointer select-none py-1 lg:pl-2 hover:text-swopa-accent-green hover:bg-gray-100"
              >
                <div className="flex items-center justify-center lg:justify-start">
                  <img alt="" src={country.icon} className="w-5 h-5 lg:h-4 lg:w-4" />
                  <span className="ml-2 hidden lg:block truncate font-normal selected:font-semibold">
                    {country.name}
                  </span>
                </div>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </>
  );
}
