import http from 'components/util/http';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

export interface Patient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  street: string;
  postal_code: string;
  city: string;
  country_code: string;
  first_appointment_in_person_url: string;
  first_appointment_virtual_url: string;
  following_appointment_url: string;
  insurance_company: number | null;
  existing_medication: string;
  informed_about_off_label_use: boolean;
  informed_about_being_banned_from_driving: boolean;
  informed_about_accompanying_survey: boolean;
  informed_about_risks_and_side_effects: boolean;
  lead_gen_information: boolean | null;
  pre_assessment: '' | 'positive' | 'negative' | 'submitted' | 'consultation';
  billing_city?: string;
  billing_country_code?: string;
  billing_first_name?: string;
  billing_last_name?: string;
  billing_postal_code?: string;
  billing_street?: string;
  height?: number;
  weight?: number;
  health_insurance_number?: string;
  insurance_type?: 'private' | 'public';
  date_of_birth?: string;
  gender?: 'm' | 'w' | 'd';
  billing_address_is_different: boolean;
  is_pregnant: boolean;
  is_breastfeeding: boolean;
  is_info_sheet_accepted: boolean;
  eligible_for_treatment: 'yes' | 'no' | 'maybe';
  eligible_for_treatment_reasons: string[];
}

export function PatientName() {
  const { data } = usePatient();
  const name = useMemo(() => {
    if (!data) return '';
    return data.first_name + ' ' + data.last_name;
  }, [data]);
  return <>{name}</>;
}

export function PatientNameShort() {
  const { data } = usePatient();
  const name = useMemo(() => {
    if (!data) return '';
    return data.first_name.charAt(0) + data.last_name.charAt(0);
  }, [data]);
  return <>{name}</>;
}

export function usePatientHasData() {
  const { data, isLoading } = usePatient();

  const hasData = useMemo(() => {
    if (!data) return undefined;
    return !!data.street && !!data.city && !!data.phone_number && !!data.postal_code;
  }, [data]);
  return { hasData, isLoading };
}

export default function usePatient() {
  return useQuery<Patient, Error>({
    queryKey: ['patient'],
    queryFn: async () => {
      const res = await http.get(`/medical/patients/me/`);
      return res;
    },
  });
}
