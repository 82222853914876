import { useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import Button from 'styles/Button';
import {
  PasswordRepeatSubForm,
  PasswordRepeatSubFormFields,
  validationSchema as passwordValidationSchema,
  defaultValues as passwordDefaultValues,
  PASSWORD,
} from 'components/subforms/PasswordRepeat';
import { useNotificationContext } from 'components/Notification';
import http from 'components/util/http';
import Layout from './Layout';
import { useAuthContext } from 'components/util/useAuth';

type FormValues = PasswordRepeatSubFormFields;

function InnerForm({
  onSuccess,
  newUser,
}: {
  onSuccess: (token: string) => void;
  newUser: boolean;
}) {
  const [params] = useSearchParams();
  const { showNotification, clearNotification } = useNotificationContext();

  return (
    <Formik<FormValues>
      initialValues={passwordDefaultValues}
      validationSchema={passwordValidationSchema}
      validateOnChange={false}
      onSubmit={(formData, { setSubmitting, setErrors }) => {
        clearNotification();
        setSubmitting(true);

        if (!params.has('uid') || !params.has('token')) {
          showNotification(
            {
              type: 'error',
              text: 'Etwas ist schiefgelaufen.',
            },
            10000,
          );
          setSubmitting(false);
          return;
        }

        http
          .post('/auth/users/reset-password-confirm/', {
            uid: params.get('uid'),
            token: params.get('token'),
            password: formData[PASSWORD],
          })
          .then(({ access }) => onSuccess(access))
          .catch((e) => {
            setErrors({ password: ' ', passwordRepeat: ' ' });
            setSubmitting(false);
            if (e.token) {
              showNotification({ type: 'error', text: 'Der Link ist leider abgelaufen.' });
            } else {
              showNotification({
                type: 'error',
                text: e.error || 'Etwas ist schiefgelaufen',
              });
            }
          });
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <PasswordRepeatSubForm />
          <Button type="submit" disabled={formikProps.isSubmitting}>
            PASSWORT {newUser ? 'Festlegen' : 'ZURÜCKSETZEN'}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

interface ResetPasswordConfigmProps {
  newUser: boolean;
}

export default function ResetPasswordConfirm({ newUser }: ResetPasswordConfigmProps) {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const auth = useAuthContext();
  const { showNotification, clearNotification } = useNotificationContext();

  const onResetPassword = (token: string) => {
    auth.autoLogin(token, (ok) => {
      if (ok) {
        navigate('/therapie');
        clearNotification();
        showNotification(
          {
            type: 'success',
            text: 'Passwort wurde erfolgreich gesetzt.',
          },
          10000,
        );
      } else {
        setSuccess(false);
      }
    });
  };

  if (success) {
    return (
      <Layout>
        <h2 className="mb-2">Erfolgreich geändert</h2>
        <p className="mb-6 text-swopa-primary-dark-blue">
          Ihr Passwort wurde erfolgreich {newUser ? 'angelegt' : 'zurückgesetzt'}. Gehen Sie zur
          Login-Seite und melden Sie sich mit Ihren neuen Zugangsdaten an.
        </p>
        <Button onClick={() => navigate('/login')}>ANMELDEN</Button>
      </Layout>
    );
  }

  return (
    <Layout>
      <h2 className="mb-2">Passwort wählen</h2>
      <span className="mb-6 text-swopa-primary-dark-blue">Bitte wählen Sie ein neues Passwort</span>
      <InnerForm onSuccess={onResetPassword} newUser={newUser} />
    </Layout>
  );
}
