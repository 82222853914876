import classNames from 'classnames';
import { useField } from 'formik';

type Option = {
  label: string;
  value: any;
};

type FormikMultiSelectProps = {
  label?: string;
  options: Option[];
  name: string;
};

export default function FormikMultiSelect({ label, options, name }: FormikMultiSelectProps) {
  const [_field, meta, helpers] = useField(name);

  const { value, error } = meta;
  const { setValue } = helpers;

  return (
    <div className="grid grid-cols-1 gap-4">
      {options.map((option) => (
        <div
          className={classNames(
            'flex items-center p-4 rounded-lg border hover:bg-swopa-secondary-light-blue-hover/30',
            {
              'bg-white': !value.includes(option.value),
              'bg-swopa-secondary-light-blue-hover/30': value.includes(option.value),
              'border-swopa-secondary-grey': !error,
              'border-swopa-warning-red': error,
            },
          )}
        >
          <input
            id={option.value}
            name={option.value}
            type="checkbox"
            className="focus:ring-swopa-primary-dark-blue h-4 w-4 text-swopa-primary-dark-blue rounded"
            checked={value.includes(option.value)}
            onChange={(e) => {
              if (e.target.checked) {
                setValue([...value, option.value]);
              } else {
                setValue(value.filter((item: any) => item !== option.value));
              }
            }}
          />
          <label
            htmlFor={option.value}
            className="ml-3 block text-base font-semibold text-swopa-primary-dark-blue cursor-pointer"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}
