import classNames from 'classnames';
import { Field } from 'formik';
import { ReactElement } from 'react';

interface InputProps {
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  label?: string | ReactElement;
}

export default function FormikCheckbox(props: InputProps) {
  const checkboxClass = classNames(
    "appearance-none w-4 h-4 rounded border border-swopa-primary-grey-light mt-1 mr-2 shrink-0 checked:border-swopa-primary-dark-blue checked:after:content-['✓'] flex items-center justify-center cursor-pointer",
    {
      'border-swopa-warning-red': props.error,
    },
  );
  return (
    <div className="flex items-start mb-4">
      <Field type={props.type} name={props.name} id={props.id} className={checkboxClass} />
      <label
        htmlFor={props.id}
        className={classNames('text-swopa-primary-dark-blue text-sm cursor-pointer', {
          'text-swopa-warning-red': props.error,
        })}
      >
        {props.label}
      </label>
    </div>
  );
}
