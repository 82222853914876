import classNames from 'classnames';
import { Field } from 'formik';

interface InputProps {
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  label?: string;
  value?: string;
}

export default function FormikRadio(props: InputProps) {
  const radioClass = classNames(
    'appearance-none w-4 h-4 border rounded-full border-swopa-primary-grey-light checked:border-swopa-primary-dark-blue checked:border-4 hover:border-swopa-primary-dark-blue hover:border hover:checked:border-4 active:swopa-primary-dark-blue cursor-pointer',
    {
      '!border-swopa-warning-red': props.error,
    },
  );
  return (
    <div className="flex items-center">
      <Field
        type={props.type}
        name={props.name}
        id={props.id}
        className={radioClass}
        value={props.value}
      />
      <label
        htmlFor={props.id}
        className="mr-4 relative pl-2 text-swopa-primary-dark-blue cursor-pointer text-sm"
      >
        {props.label}
      </label>
    </div>
  );
}
