import classnames from 'classnames';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

type NotificationType = { type: 'error' | 'success'; text: string };

type NotificationContextType = {
  NotificationOutlet: React.FunctionComponent<{}>;
  showNotification: (notification: NotificationType, time?: number) => void;
  clearNotification: () => void;
};

const NotificationContext = createContext<null | NotificationContextType>(null);

export const NotificationContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const timeout = useRef<any>(null);
  const [state, setState] = useState<null | NotificationType>(null);

  const showNotification = useCallback(
    (notification: NotificationType, time?: number) => {
      setState(notification);

      clearTimeout(timeout.current);

      if (time !== undefined && time > 0) {
        timeout.current = setTimeout(() => setState(null), time);
      }
    },
    [setState],
  );

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  const clearNotification = useCallback(() => {
    setState(null);
    clearTimeout(timeout.current);
  }, [setState]);

  const value: NotificationContextType = useMemo(
    () => ({
      showNotification,
      clearNotification,
      NotificationOutlet: () => {
        if (state === null) {
          return <></>;
        }

        return <Notification {...state} />;
      },
    }),
    [state, showNotification, clearNotification],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

function Notification({ text, type }: React.PropsWithChildren<NotificationType>) {
  return (
    <div
      className={classnames(`top-0 left-0 w-full fixed z-[9999]`, {
        'bg-swopa-warning-red text-white': type === 'error',
        'bg-swopa-accent-green text-swopa-primary-dark': type === 'success',
      })}
    >
      <div
        className="flex justify-center items-center font-bold py-2 px-8 md:px-[133px] lg:px-0 notification-banner"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}

export const useNotificationContext = (): NotificationContextType => {
  const ctx = useContext(NotificationContext);
  if (ctx === null) {
    throw new Error('NotificationContext missing.');
  }

  return ctx;
};
