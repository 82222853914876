import Container from 'styles/Prescription/Container';
import Grid, { GridItem } from 'styles/Prescription/Grid';
import { format } from 'date-fns';
import StepFailure from './StepFailure';
import { Fragment } from 'react';

export interface FinalPrescriptionProps {
  ready: boolean;
  prescriptionProps?: {
    medicine?: string;
    amount?: string;
    dosage?: string;
    indica?: string;
    proportion?: string;
    date: Date;
  };
  index?: number;
  total?: number;
}

const GRID_ITEMS_DEFINITION: Array<{ title: string; key: string }> = [
  {
    title: 'Medikament',
    key: 'medicine',
  },
  {
    title: 'Cannabis Sorte',
    key: 'indica',
  },
  {
    title: 'Menge',
    key: 'amount',
  },
  {
    title: 'Anteil THC/CBD',
    key: 'proportion',
  },
  {
    title: 'Empfohlene Dosierung',
    key: 'dosage',
  },
];
/**
 * Component to display Prescription Data
 */
export default function FinalPrescription({
  ready,
  prescriptionProps,
  index,
  total,
}: FinalPrescriptionProps) {
  if (!ready) return <></>;

  if (!prescriptionProps) {
    return (
      <StepFailure text="Wir können Ihnen leider keine Verschreibung anbieten." link="/service" />
    );
  }
  const prescriptionsCount = total || 0;
  const prescriptionIndex = index || 0;
  return (
    <Container>
      <span className="text-swopa-primary-dark-blue uppercase font-radikal">
        Verschreibung {prescriptionsCount > 1 ? `NR. ${prescriptionIndex + 1}` : ''}
      </span>
      <div className="mt-4 xl:-mt-8 flex flex-col xl:flex-row-reverse xl:items-end xl:justify-between xl:space-x-10 xl:space-x-reverse">
        <div className="xl:grow">
          <Grid>
            {GRID_ITEMS_DEFINITION.map(({ title, key }, index) => (
              <Fragment key={index}>
                {prescriptionProps[key as keyof typeof prescriptionProps] && (
                  <GridItem title={title}>
                    <span className={key === 'indica' ? 'capitalize' : ''}>
                      {prescriptionProps[key as keyof typeof prescriptionProps]?.toString() ||
                        'Nicht Angegeben'}
                    </span>
                  </GridItem>
                )}
              </Fragment>
            ))}
            <GridItem title="Ausstellungsdatum">
              {format(prescriptionProps.date, 'dd.MM.yyyy')}
            </GridItem>
          </Grid>
        </div>
        <div className="mt-5 xl:mt-0 xl:w-[260px] shrink-0"></div>
      </div>
    </Container>
  );
}
