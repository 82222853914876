import classNames from 'classnames';
import { Field } from 'formik';
import { useEffect } from 'react';

interface DatepickerProps {
  id: string;
  type: string;
  name: string;
  placeholder: string;
  error?: boolean;
  errorMessage?: any;
  className?: string;
  label?: string;
  min?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function FormikDatepicker(props: DatepickerProps) {
  // workaround to fix placeholder not hidden when value
  useEffect(() => {
    const datePickerElem = document.getElementById(props.id) as HTMLInputElement;
    const is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
    const is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    if (!(is_ios && is_safari) || datePickerElem?.value) {
      datePickerElem?.setAttribute('placeholder', '');
    }
  }, []);

  return (
    <div className="flex flex-col">
      <label className="text-sm text-swopa-primary-dark-blue mb-2" htmlFor={props.id}>
        {props.label}
      </label>
      <Field
        className={classNames(
          'appearance-none h-12 bg-[#F8F8F8] border border-transparent p-3 rounded placeholder:text-swopa-primary-grey outline-none text-swopa-primary-dark-blue focus:border focus:border-swopa-primary-dark-blue text-base md:text-sm',
          {
            '!border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red':
              props.error,
          },
        )}
        type={props.type}
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        {...(props.min && { min: props.min })}
        onFocus={(e: any) => (e.target.placeholder = '')}
        {...(props.onChange && { onChange: props.onChange })}
      />
      {props.error && props.errorMessage && (
        <span className="text-swopa-warning-red mt-2 text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
}
