type ProgressBarProps = {
  max: number;
  current: number;
};

export default function ProgressBar({ max, current }: ProgressBarProps) {
  const percentage = (current / max) * 100;

  return (
    <div className="w-full h-4 mb-4 bg-swopa-grey-2 rounded-full">
      <div
        className="h-4 bg-swopa-primary-dark-blue rounded-full dark:bg-blue-500"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
}
