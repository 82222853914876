import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { differenceInYears } from 'date-fns';
import classNames from 'classnames';
import { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import FormikInput from 'styles/Signup/FormikInput';
import FormikPasswordInput from 'styles/Signup/FormikPasswordInput';
import FormikRadio from 'styles/Signup/FormikRadio';
import FormikDatepicker from 'styles/Signup/FormikDatepicker';
import FormikCheckbox from 'styles/Signup/FormikCheckbox';

import http from 'components/util/http';
import { useNotificationContext } from 'components/Notification';
import { useAuthContext } from 'components/util/useAuth';
import Spinner from 'components/Spinner';

const options = [
  {
    label: 'Migräne',
    value: 'Migräne',
  },
  {
    label: 'Chronische Schmerzen',
    value: 'Chronische Schmerzen',
  },
  {
    label: 'ADHS',
    value: 'ADHS',
  },
  {
    label: 'Depressionen',
    value: 'Depressionen',
  },
  {
    label: 'Schlafstörungen',
    value: 'Schlafstörungen',
  },
];

type FormValues = {
  gender: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  email: string;
  password: string;
  password_confirm: string;
  agb_subscriber: boolean;
  data_share_subscriber: boolean;
  data_storage_subscriber: boolean;
  is_newsletter_subscriber: boolean;
};

const validationSchema = Yup.object().shape({
  gender: Yup.string().required('Bitte geben Sie Ihr Geschlecht an'),
  first_name: Yup.string().required('Bitte geben Sie Ihren Namen ein'),
  last_name: Yup.string().required('Bitte geben Sie Ihren Nachnamen ein'),
  date_of_birth: Yup.date()
    .required('Bitte geben Sie Ihr Alter an')
    .test('min_age', 'Sie müssen mindestens 21 Jahre alt sein', (value: any) => {
      return (differenceInYears(new Date(), new Date(value)) >= 21) as any;
    }),
  email: Yup.string().required('Bitte geben Sie Ihre E-Mail-Adresse ein'),
  password: Yup.string()
    .required('Passwort ist ein Pflichtfeld')
    .min(8, 'Passwort muss aus mindestens 8 Zeichen bestehen')
    .minNumbers(1, 'Mindestens eine Zahl'),
  password_confirm: Yup.string()
    .required('Passwort wiederholen ist ein Pflichtfeld')
    .oneOf([Yup.ref('password'), null], 'Die Passwörter müssen übereinstimmen')
    .min(8, 'Passwort muss aus mindestens 8 Zeichen bestehen')
    .minNumbers(1, 'Mindestens eine Zahl'),
  agb_subscriber: Yup.bool().oneOf([true]),
  data_share_subscriber: Yup.bool().oneOf([true]),
  data_storage_subscriber: Yup.bool().oneOf([true]),
});

export default function RegisterV2() {
  const [ailments, setAilments] = useState([]) as any;
  const [customAilment, setCustomAilment] = useState('');
  const [loading, setLoading] = useState(false);
  const { showNotification, clearNotification } = useNotificationContext();
  const auth = useAuthContext();
  const navigate = useNavigate();

  const handleSubmit = async (formValues: FormValues) => {
    const patientAilments = customAilment !== '' ? [...ailments, customAilment] : ailments;
    const data = { ...formValues, complaints: patientAilments };

    // atleast one ailment needs to be selected, todo: handle by formik validation
    if (!ailments.length && !customAilment.length) {
      return showNotification(
        {
          type: 'error',
          text: 'Bitte wählen Sie eine Beschwerde aus, um fortzufahren.',
        },
        3000,
      );
    }

    try {
      setLoading(true);
      clearNotification();
      await http.post('medical/validate/', { ...data, step: 3 });
      const response = await http.post('medical/register/', data);
      const token = response.access;
      auth.autoLogin(token, (ok) => {
        if (ok) {
          clearNotification();
          navigate('/therapie');
        } else {
          showNotification({
            type: 'error',
            text: 'Etwas ist schief gelaufen',
          });
        }
      });
    } catch (error: any) {
      const msg = (Object.values(error)[0] as any)[0];
      const gtmError = (Object.values(error)[0] as any)[1];
      showNotification(
        {
          type: 'error',
          text: msg,
        },
        15000,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSetAilments = (e: MouseEvent) => {
    e.preventDefault();
    const target = e.target as HTMLButtonElement;
    const selectedAilment = target.dataset.tag;
    if (ailments?.includes(selectedAilment)) {
      ailments.splice(ailments.indexOf(selectedAilment), 1);
      setAilments([...ailments]);
    } else {
      setAilments([...ailments, selectedAilment]);
    }
  };

  const handleSetCustomAilment = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomAilment(value);
  };

  return (
    <>
      <div className="m-auto mb-10 max-w-lg">
        <span className="mb-2 font-radikal uppercase text-swopa-accent-green text-xs text-center block">
          Therapie anfragen
        </span>
        <h1 className="text-swopa-primary-dark-blue mb-4 text-2xl md:text-3xl text-center">
          Ihr Weg zur persönlichen Cannabis-Therapie
        </h1>
        <h2 className="text-swopa-primary-grey text-center text-sm font-normal font-sans">
          Füllen Sie folgende Felder aus und fragen Sie schnell und unkompliziert Ihre persönliche
          Therapie mit medizinischem Cannabis an.
        </h2>
      </div>

      <Formik
        initialValues={{
          gender: '',
          first_name: '',
          last_name: '',
          date_of_birth: '',
          email: '',
          password: '',
          password_confirm: '',
          agb_subscriber: false,
          data_share_subscriber: false,
          data_storage_subscriber: false,
          is_newsletter_subscriber: false,
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <div className="max-w-lg m-auto">
              {/* personal data */}
              <div className="flex mb-6 flex-col">
                <div className="flex">
                  <FormikRadio
                    type="radio"
                    name="gender"
                    id="m"
                    label="Herr"
                    value="m"
                    error={!!(formikProps.touched.gender && formikProps.errors.gender)}
                  />
                  <FormikRadio
                    type="radio"
                    name="gender"
                    id="w"
                    label="Frau"
                    value="w"
                    error={!!(formikProps.touched.gender && formikProps.errors.gender)}
                  />
                  <FormikRadio
                    type="radio"
                    name="gender"
                    id="d"
                    label="Divers"
                    value="d"
                    error={!!(formikProps.touched.gender && formikProps.errors.gender)}
                  />
                </div>
                {!!(formikProps.touched.gender && formikProps.errors.gender) && (
                  <span className="text-swopa-warning-red mt-2 text-xs">
                    {formikProps.errors.gender}
                  </span>
                )}
              </div>
              <div className="grid grid-cols-2 gap-2 mb-4">
                <FormikInput
                  label="Vorname"
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="Vorname"
                  error={!!(formikProps.touched.first_name && formikProps.errors.first_name)}
                  errorMessage={formikProps.errors.first_name}
                />
                <FormikInput
                  label="Nachname"
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Nachname"
                  error={!!(formikProps.touched.last_name && formikProps.errors.last_name)}
                  errorMessage={formikProps.errors.last_name}
                />
              </div>

              <div className="mb-4">
                <FormikDatepicker
                  label="Geburtsdatum"
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                  min="1900-01-01"
                  placeholder="tt/mm/jjjj"
                  error={!!(formikProps.touched.date_of_birth && formikProps.errors.date_of_birth)}
                  errorMessage={formikProps.errors.date_of_birth}
                />
              </div>
              <div className="mb-4">
                <FormikInput
                  label="E-Mail-Adresse"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="mustername@mail.de"
                  error={!!(formikProps.touched.email && formikProps.errors.email)}
                  errorMessage={formikProps.errors.email}
                />
              </div>

              <div className="grid grid-cols-2 gap-2 mb-4">
                <FormikPasswordInput
                  label="Passwort"
                  name="password"
                  id="password"
                  placeholder="min. 8 Zeichen"
                  error={!!(formikProps.touched.password && formikProps.errors.password)}
                  errorMessage={formikProps.errors.password}
                />
                <FormikPasswordInput
                  label="Passwort wiederholen"
                  name="password_confirm"
                  id="password_confirm"
                  placeholder="min. 8 Zeichen"
                  error={
                    !!(formikProps.touched.password_confirm && formikProps.errors.password_confirm)
                  }
                  errorMessage={formikProps.errors.password_confirm}
                />
              </div>

              <span className="text-[#898D8D] text-xs block mb-14">
                Ihr Passwort muss aus mindestens 8 Zeichen bestehen und eine Zahl beinhalten.
              </span>
            </div>

            {/* patient conditions */}
            <div className="max-w-lg m-auto">
              <h3 className="text-swopa-primary-dark-blue font-radikal mb-4 text-base text-left">
                Unter welchen Beschwerden leiden Sie?
              </h3>
              <ul className="max-w-lg w-full mb-14 grid sm:grid-cols-2 gap-3">
                {options.map((diagnose: any, index: number) => (
                  <li className="mb-2 sm:mb-0" key={index}>
                    <AilmentItem diagnose={diagnose} handleSetAilments={handleSetAilments} />
                  </li>
                ))}
                <li
                  className={classNames(
                    'border border-swopa-primary-light rounded py-3 px-4 mb-2 cursor-pointer hover:border-swopa-primary-dark-blue',
                    {
                      'bg-swopa-primary-dark-blue': customAilment !== '',
                    },
                  )}
                >
                  <input
                    type="text"
                    placeholder="Etwas anderes"
                    className={classNames(
                      'text-base md:text-sm text-swopa-primary-dark-blue placeholder:text-swopa-primary-dark-blue outline-none w-full bg-transparent',
                      {
                        'bg-swopa-primary-dark-blue': customAilment !== '',
                        'text-swopa-primary-white': customAilment !== '',
                      },
                    )}
                    onChange={handleSetCustomAilment}
                    value={customAilment}
                  />
                </li>
              </ul>
            </div>

            {/* terms & conditions */}
            <div className="max-w-lg m-auto">
              <div className="text-swopa-primary-dark-blue mb-16">
                <FormikCheckbox
                  type="checkbox"
                  name="agb_subscriber"
                  id="agb_subscriber"
                  label={
                    <span>
                      Ich habe die{' '}
                      <a
                        target="_blank"
                        href="https://www.enmedify.com/agb"
                        className="text-swopa-accent-green underline"
                      >
                        AGB
                      </a>{' '}
                      zur Kenntnis genommen
                    </span>
                  }
                  error={
                    !!(formikProps.touched.agb_subscriber && formikProps.errors.agb_subscriber)
                  }
                />

                <p className="mb-5">
                  Ich erkläre mein jederzeit widerrufbares Einverständnis in die Verarbeitung{' '}
                  <b>meiner personenbezogenen Daten</b> (insb. auch <b>Gesundheitsdaten</b>) zu den
                  nachfolgenden Zwecken (siehe{' '}
                  <a
                    target="_blank"
                    href="https://www.enmedify.com/datenschutz"
                    className="text-swopa-accent-green underline"
                  >
                    Datenschutzerklärung
                  </a>
                  ):
                </p>

                <FormikCheckbox
                  type="checkbox"
                  name="data_share_subscriber"
                  id="data_share_subscriber"
                  label="zur Terminbuchung und zur Vermittlung von Ärzt:innen"
                  error={
                    !!(
                      formikProps.touched.data_share_subscriber &&
                      formikProps.errors.data_share_subscriber
                    )
                  }
                />
                <FormikCheckbox
                  type="checkbox"
                  name="data_storage_subscriber"
                  id="data_storage_subscriber"
                  label="zur anonymisierten statistischen Auswertung"
                  error={
                    !!(
                      formikProps.touched.data_storage_subscriber &&
                      formikProps.errors.data_storage_subscriber
                    )
                  }
                />
                <FormikCheckbox
                  type="checkbox"
                  name="is_newsletter_subscriber"
                  id="is_newsletter_subscriber"
                  label="zum Erhalt des Enmedify E-Mail Newsletters (optional)"
                />
              </div>
            </div>

            {/* submit button */}
            <div className="md:px-6 mb-8 w-full text-center">
              {loading && (
                <Spinner className="fill-swopa-primary-dark w-5 h-5 absolute top-4 left-[50%] ml-2.5 z-10" />
              )}
              <button
                className="bg-swopa-accent-green hover:bg-swopa-accent-green-hover text-swopa-primary-dark-blue uppercase p-4 w-full flex items-center justify-center rounded mb-4 font-radikal text-sm"
                type="submit"
              >
                Therapie anfragen
              </button>
              <span className="text-swopa-primary-grey text-sm">
                Sie haben bereits einen Account?{' '}
                <a href="/login" className="underline cursor-pointer text-swopa-accent-green">
                  Login
                </a>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const AilmentItem = ({
  diagnose,
  handleSetAilments,
}: {
  diagnose: any;
  handleSetAilments: any;
}) => {
  const [active, setActive] = useState(false);

  const handleActive = (e: any) => {
    handleSetAilments(e);
    setActive(!active);
  };

  return (
    <button
      className={classNames(
        'text-base md:text-sm border border-swopa-primary-light rounded py-3 px-4 text-swopa-primary-dark-blue cursor-pointer hover:border-swopa-primary-dark-blue w-full text-left',
        {
          'bg-swopa-primary-dark-blue': active,
          'text-swopa-primary-white': active,
        },
      )}
      data-tag={diagnose.value}
      onClick={handleActive}
    >
      {diagnose.label}
    </button>
  );
};
