interface ContainerProps {
  children: React.ReactNode;
  additional?: React.ReactNode;
  noLine?: boolean;
}

export default function Container({ children, additional, noLine = false }: ContainerProps) {
  return (
    <>
      {!noLine && <hr className="bg-swopa-border" />}
      <div className="bg-swopa-hover-grey my-4 md:my-6 p-6 rounded-[4px]">{children}</div>
      {additional}
    </>
  );
}
