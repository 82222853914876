import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import useOutsideAlerter from './util/useOutside';
import usePreventScrollEffect from './util/usePreventScrollEffect';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';

interface IframeModalProps {
  iframeProps: React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  >;
  onClose: () => void;
}

export function IframeModal({ onClose, iframeProps }: IframeModalProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const portalDiv = document.getElementById('portal') || document.createElement('div');
  const ref = useRef<HTMLDivElement>(null);

  useOutsideAlerter({ ref, callback: onClose });
  usePreventScrollEffect();

  return ReactDOM.createPortal(
    <div
      className="p-5 md:p-16 top-0 left-0 w-full h-full z-50 filter backdrop-blur-md fixed bg-white/50 overflow-scroll"
      onClick={onClose}
      ref={ref}
    >
      {!isLoaded && <span className="absolute top-1/2 left-1/2">Bitte warten</span>}
      {isLoaded && (
        <>
          <span
            className="flex flex-row absolute top-16 right-16 p-4 -translate-y-full cursor-pointer"
            onClick={onClose}
          >
            <CancelIcon className="mr-2" /> Schließen
          </span>
        </>
      )}
      <iframe
        {...iframeProps}
        onLoad={() => setIsLoaded(true)}
        className={`w-full h-[calc(100vh-128px)] overflow-y-scroll ${iframeProps.className}`}
      />
    </div>,
    portalDiv,
  );
}
