import Sidebar from 'components/Onboarding/Sidebar';
import Header from 'components/Navigation/Header';
import { RequireAuth } from 'components/RequireAuth';

interface SignupLayoutProps {
  children: React.ReactNode;
}

export default function EterminLayout({ children }: SignupLayoutProps) {
  return (
    <RequireAuth>
      <div className="w-screen flex flex-col lg:flex-row overflow-x-hidden mt-20 xl:mt-0">
        <div className="lg:w-[70%] xxl:w-[80%] lg:overflow-y-auto">
          <Header isOnboarding />
          <div
            id="iframe-wrapper"
            className="max-w-[865px] xxl:max-w-[1115px] h-[calc(100vh-160px)] sm:h-[calc(100vh-80px)] xl:h-[calc(100vh-129px)] m-auto relative"
          >
            {children}
          </div>
        </div>
        <Sidebar />
      </div>
    </RequireAuth>
  );
}
