import { useQuery } from '@tanstack/react-query';
import http from 'components/util/http';
import { useState } from 'react';
import Box from 'styles/Box';
import Button from 'styles/Button';
import Modal from 'styles/Modal';
import Rating from './Rating';

type RatingItem = {
  id: number;
  question: string;
  question_type: 'rating' | 'text';
  step: number;
  options?: {
    min?: number;
    max?: number;
    step?: number;
  };
};

type RatingModalProps = {
  open: boolean;
  onClose: () => void;
  appointmentId: number;
};

export default function RatingModal({ open, onClose, appointmentId }: RatingModalProps) {
  const { data } = useQuery<RatingItem[]>({
    queryKey: ['ratings'],
    queryFn: async () => {
      const res = await http.get('/medical/survey-questions/');
      return res.results;
    },
  });
  const [ratingValues, setRatingValues] = useState<Record<string, number | string>>({});

  if (!open) return <></>;

  const ratings =
    data
      ?.filter((rating) => rating.question_type === 'rating')
      .map((rating) => ({
        key: rating.id.toString(),
        label: rating.question,
        min: rating.options?.min || 1,
        max: rating.options?.max || 10,
        step: rating.options?.step || 1,
      })) || [];

  const textRatings = data?.filter((rating) => rating.question_type === 'text');

  return (
    <Modal onClose={onClose}>
      <Box className="p-6 max-w-lg">
        <h2 className="mb-5 text-2xl">Bewerten Sie ihr Gespräch</h2>
        {ratings.length > 0 && (
          <Rating
            ratings={ratings}
            onChange={(ratings) => {
              const ratingValues: Record<string, number> = {};
              ratings.forEach((rating) => {
                ratingValues[rating.key] = rating.value || 1;
              });
              setRatingValues((prev) => ({ ...prev, ...ratingValues }));
            }}
          />
        )}
        {textRatings?.map((rating) => (
          <div key={rating.id} className="flex flex-col mt-5 w-full">
            <label
              className="text-swopa-primary-dark-blue text-base font-radikal"
              htmlFor={rating.id.toString()}
            >
              {rating.question}
            </label>
            <textarea
              className="w-full h-20 p-2 mt-1 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-1 focus:ring-swopa-primary-light-blue focus:border-transparent"
              id={rating.id.toString()}
              value={ratingValues[rating.id.toString()]}
              onChange={(e) =>
                setRatingValues((prev) => ({
                  ...prev,
                  [rating.id.toString()]: e.target.value,
                }))
              }
            />
          </div>
        ))}
        <Button
          disabled={Object.keys(ratingValues).length < (data?.length || 1)}
          className="mt-5"
          onClick={() => {
            http.post(`/medical/patient-appointments/${appointmentId}/feedback/`, {
              feedback_answers: Object.entries(ratingValues).map(([question, answer]) => ({
                question,
                answer,
              })),
            });
            onClose();
          }}
        >
          Senden
        </Button>
      </Box>
    </Modal>
  );
}
