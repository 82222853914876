import { Formik, Form } from 'formik';
import Input from 'styles/FormikInput';
import { string, object } from 'yup';
import Button from 'styles/Button';
import http from 'components/util/http';
import { useNotificationContext } from 'components/Notification';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Title from 'components/util/Title';
import Layout from './Layout';

interface InnerFormProps {
  onSuccess: () => void;
  initialEmail?: string;
}

type ForgotPasswordFormValues = {
  email: string;
};

const validationSchema = object({
  email: string()
    .required('Bitte geben Sie ihre E-Mail-Adresse ein')
    .email('Ungültige E-Mail-Adresse'),
});

function InnerForm(props: InnerFormProps) {
  const { showNotification, clearNotification } = useNotificationContext();
  return (
    <Formik<ForgotPasswordFormValues>
      onSubmit={(formData) => {
        clearNotification();

        http
          .post('/auth/users/reset-password/', { email: formData.email })
          .then(() => props.onSuccess())
          .catch(() => {
            // There is no known error case here so far, even if an E-Mail doesn't exist there is no error returned
            // this is just a catch all for unexpected http errors
            showNotification({ type: 'error', text: 'Etwas ist schiefgelaufen.' });
          });
      }}
      validationSchema={validationSchema}
      initialValues={{ email: props.initialEmail || '' }}
    >
      {({ touched, errors, isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="E-Mail-Adresse"
            error={!!(touched.email && errors.email)}
            errorMessage={errors.email}
            className="mb-4"
          />
          <Button type="submit" disabled={isSubmitting}>
            LINK ANFORDERN
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <Layout>
        <Title title="Passwort zurücksetzen" />
        <h2 className="mb-2 whitespace-nowrap">Link versendet</h2>
        <p className="text-swopa-primary-dark-blue mb-6">
          Wir haben Ihnen einen Link per E-Mail gesendet, mit dem Sie Ihr Passwort zurücksetzen
          können. Überprüfen Sie Ihren Spam-Ordner, falls Sie in den nächsten Minuten noch keine
          E-Mail von uns erhalten haben.
        </p>
        <Button onClick={() => navigate('/login')}>ZUR ANMELDUNG</Button>
      </Layout>
    );
  }

  return (
    <Layout>
      <Title title="Passwort zurücksetzen" />
      <h2 className="mb-2 whitespace-nowrap">Passwort zurücksetzen</h2>
      <span className="text-swopa-primary-dark-blue mb-6">
        Bitte nennen Sie uns Ihre E-Mail-Adresse. <br />
        Wir senden Ihnen einen Link, mit dem Sie Ihre Passwort zurücksetzen können.
      </span>
      <InnerForm
        onSuccess={() => setSuccess(true)}
        initialEmail={params.get('email')?.toString() || ''}
      />
      <span
        className="text-swopa-secondary-grey cursor-pointer mt-2 hover:text-swopa-accent-green"
        onClick={() => navigate('/login')}
      >
        Zurück zur Anmeldung
      </span>
    </Layout>
  );
}
