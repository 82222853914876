import http from 'components/util/http';
import { useQuery } from '@tanstack/react-query';

interface Document {
  id: number;
  patient: number;
  asset: string;
  uploaded_by: number;
  uploaded_at: Date;
  name: string;
  content_type: string;
}

export default function useDocuments() {
  return useQuery<Array<Document>, Error>({
    queryKey: ['documents'],
    queryFn: async () => {
      const { results } = await http.get('/medical/documents/');
      if (!results || !results.length) return [];
      return results;
    },
  });
}
