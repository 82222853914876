import React from 'react';

export default function ErrorBox({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="border border-swopa-warning-red bg-swopa-warning-red/10 relative py-2 pr-3 pl-10 rounded-[4px]">
      <div className="absolute top-[10px] left-4">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 4.66699V8.66699"
            stroke="#E9082A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 11.3404L8.00667 11.333"
            stroke="#E9082A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.99998 14.6663C11.6818 14.6663 14.6666 11.6815 14.6666 7.99967C14.6666 4.31777 11.6818 1.33301 7.99998 1.33301C4.31808 1.33301 1.33331 4.31777 1.33331 7.99967C1.33331 11.6815 4.31808 14.6663 7.99998 14.6663Z"
            stroke="#E9082A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="text-swopa-warning-red">{children}</div>
    </div>
  );
}
