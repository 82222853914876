import { Field } from 'formik';
import { useState } from 'react';
import classnames from 'classnames';

interface InputProps {
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  postFix?: string;
}

export default function Input(props: InputProps) {
  const [focus, setFocus] = useState(false);

  return (
    <div className={`relative w-full ${props.className ? props.className : ''}`}>
      <Field
        id={props.id}
        name={props.name}
        type={props.type}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        placeholder={props.placeholder}
        className={`appearance-none border bg-swopa-hover-grey text-base sm:text-sm placeholder:text-base sm:placeholder:text-sm ${
          props.error
            ? 'border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red'
            : 'border-swopa-border text-swopa-primary-dark-blue placeholder:text-swopa-secondary-grey'
        } rounded w-full py-3 px-4  focus:outline-swopa-primary-dark-blue`}
      />
      {props.postFix && (
        <div
          className={classnames(
            `absolute right-0 top-0 h-full bg-swopa-border flex justify-center text-swopa-primary-dark-blue items-center w-12 rounded-r-[4px]`,
            {
              'border border-swopa-warning-red border-l-0 text-swopa-warning-red':
                props.error && !focus,
              'border-[2px] border-swopa-primary-dark-blue border-l-0': focus,
            },
          )}
        >
          <span>{props.postFix}</span>
        </div>
      )}
      {props.error && props.errorMessage && (
        <span className="text-swopa-warning-red text-field-validation mt-2">
          {props.errorMessage}
        </span>
      )}
    </div>
  );
}
