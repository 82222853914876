import Box from 'styles/Box';
import Footer from 'components/Footer';
import { useNotificationContext } from 'components/Notification';
import useLocalizedImages from '../../hooks/useLocalizedImages';
import CountrySelect from 'components/Navigation/CountrySelect/CountrySelect';

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  const { NotificationOutlet } = useNotificationContext();
  const { logo } = useLocalizedImages();
  return (
    <>
      <NotificationOutlet />
      <section className="grid grid-rows-login min-h-screen">
        <div className="flex justify-center items-center">
          <div className="flex flex-col justify-start max-w-[580px] w-full mx-3 md:mx-0">
            <img src={logo} alt="" className="w-32 mb-4" />
            <CountrySelect />
            <Box>
              <div className="flex flex-col m-6 md:mt-10 md:mb-8 md:mx-[100px]">{children}</div>
            </Box>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}
