import classNames from 'classnames';
import { Field } from 'formik';

interface InputProps {
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: any;
  className?: string;
  label?: string;
  min?: string;
}

export default function FormikInput(props: InputProps) {
  return (
    <div className="flex flex-col">
      <label className="text-sm text-swopa-primary-dark-blue mb-2" htmlFor={props.id}>
        {props.label}
      </label>
      <Field
        className={classNames(
          'bg-[#F8F8F8] border border-transparent p-3 rounded placeholder:text-swopa-primary-grey outline-none text-swopa-primary-dark-blue focus:border focus:border-swopa-primary-dark-blue text-base md:text-sm',
          {
            '!border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red':
              props.error,
          },
        )}
        type={props.type}
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        {...(props.min && { min: props.min })}
      />
      {props.error && props.errorMessage && (
        <span className="text-swopa-warning-red mt-2 text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
}
