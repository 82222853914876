import { Listbox, Menu, Transition } from '@headlessui/react';
import { useField } from 'formik';
import { useEffect, useState } from 'react';

interface DropdownOption {
  key: any;
  value: string | number;
}

interface DropdownProps {
  options: DropdownOption[];
  name?: string;
  error?: boolean;
  placeholder?: string;
  selected?: DropdownOption;
  onChange?: (option: any) => void;
}

export default function Dropdown({
  options,
  name,
  error,
  placeholder = '',
  selected,
  onChange,
}: DropdownProps) {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(selected);
  const [field] = useField({ name: name || '' });

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  useEffect(() => {
    if (onChange && selectedOption) {
      onChange(selectedOption);
    }
  }, [onChange, selectedOption]);

  return (
    <Listbox
      name={name}
      value={selectedOption}
      onChange={(option) => {
        setSelectedOption(option);
        field.onChange({ target: { value: option?.value || '', name } });
      }}
    >
      <div className="relative h-full">
        <Listbox.Button
          className={`border h-full bg-swopa-hover-grey ${
            error
              ? 'border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red'
              : 'border-swopa-border text-swopa-primary-dark-blue placeholder:text-swopa-secondary-grey'
          } rounded w-full py-3 px-4 text-left`}
        >
          {selectedOption ? (
            <>{selectedOption.value}</>
          ) : (
            <span className={`${error ? 'text-swopa-warning-red' : 'text-swopa-secondary-grey'} `}>
              {placeholder}
            </span>
          )}
        </Listbox.Button>
        <Transition
          className="left-0  z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white border border-swopa-border text-swopa-primary-dark-blue"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Listbox.Options>
            {options.map((option) => (
              <Listbox.Option
                className={
                  'hover:bg-swopa-border py-2 px-3 border-b border-b-swopa-border last:border-none'
                }
                key={option.key}
                value={option}
              >
                {option.value}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
