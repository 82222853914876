import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import Waiting, { waitingContainerClasses } from './Waiting';
import Container from 'styles/Prescription/Container';

export interface PrecheckProps {
  ready: boolean;
}

export default function Precheck({ ready }: PrecheckProps) {
  if (!ready)
    return (
      <Waiting text="Ärztliche Vorprüfung in Bearbeitung.">
        <div className="flex flex-col justify-center items-center text-swopa-secondary-grey mt-4">
          <span className="max-w-[560px] text-center">
            Eine ärztliche Vorprüfung kann bis zu 24 Stunden dauern. Sobald sich Ihr Status ändert,
            werden Sie von uns per E-Mail benachrichtigt.
            <br /> –<br /> Bei einer positiven Vorprüfung können Sie hier Ihren Termin buchen.
          </span>
        </div>
      </Waiting>
    );
  return null;
}
