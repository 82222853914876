import http from 'components/util/http';
import { Form, Formik, FormikErrors } from 'formik';
import { useMemo } from 'react';
import Button from 'styles/Button';
import Dropdown from 'styles/Dropdown';
import Input from 'styles/FormikInput';
import usePatient from 'components/Navigation/usePatient';
import { useNotificationContext } from 'components/Notification';

interface HealthDataFormValues {
  birthday: string;
  birthmonth: string;
  birthyear: string;
  height?: number;
  weight?: number;
  gender: string;
  healthInsuranceCompany: string;
  healthInsuranceType: string;
  healthInsuranceNumber: string;
}

const MANDATORY_VALUES = [
  'birthday',
  'birthmonth',
  'birthyear',
  'height',
  'weight',
  'gender',
  'healthInsuranceType',
];

const MONTHS = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const GENDER = [
  {
    key: 'm',
    value: 'Männlich',
  },
  {
    key: 'w',
    value: 'Weiblich',
  },
  {
    key: 'd',
    value: 'Andere',
  },
];

const INSURANCE = [
  {
    key: 'private',
    value: 'Privat',
  },
  {
    key: 'public',
    value: 'Gesetzlich',
  },
];

export default function HealthDataForm() {
  const { data: patient } = usePatient();
  const { showNotification } = useNotificationContext();

  const { day, month, year, gender, insurance } = useMemo(() => {
    if (!patient || !patient.date_of_birth) {
      return {
        day: undefined,
        month: undefined,
        year: undefined,
        gender: GENDER.find(({ key }) => key === patient?.gender)?.value,
        insurance: INSURANCE.find(({ key }) => key === patient?.insurance_type)?.value,
      };
    }
    const temp = new Date(patient.date_of_birth);
    return {
      day: temp.getDate() + '',
      month: MONTHS[temp.getMonth()],
      year: temp.getFullYear() + '',
      gender: GENDER.find(({ key }) => key === patient?.gender)?.value,
      insurance: INSURANCE.find(({ key }) => key === patient?.insurance_type)?.value,
    };
  }, [patient]);

  const birthdates = useMemo(
    () => Array.from(Array(31).keys()).map((val) => ({ key: val, value: val + 1 })),
    [],
  );

  const years = useMemo(
    () =>
      Array.from(Array(100).keys()).map((val) => ({
        key: val,
        value: new Date().getFullYear() - val,
      })),
    [],
  );

  if (!patient) {
    return <></>;
  }

  return (
    <Formik<HealthDataFormValues>
      validateOnChange={true}
      initialValues={{
        birthday: day || '',
        birthmonth: month || '',
        birthyear: year || '',
        gender: gender || '',
        healthInsuranceCompany: patient.insurance_company?.toString() || '',
        healthInsuranceType: insurance || '',
        healthInsuranceNumber: patient.health_insurance_number || '',
        weight: patient.weight,
        height: patient.height,
      }}
      validate={(formData) => {
        let errors: FormikErrors<HealthDataFormValues> = {};
        MANDATORY_VALUES.forEach((key: string) => {
          if (!formData[key as keyof HealthDataFormValues]) {
            errors[key as keyof HealthDataFormValues] = ' ';
          }
        });
        return errors;
      }}
      onSubmit={async (formData, { setSubmitting }) => {
        const res = await http.patch(`/medical/patients/me/`, {
          date_of_birth: `${formData.birthyear}-${
            MONTHS.findIndex((month) => month === formData.birthmonth) + 1
          }-${formData.birthday}`,
          gender: GENDER.find(({ value }) => value === formData.gender)?.key,
          insurance_type: INSURANCE.find(({ value }) => value === formData.healthInsuranceType)
            ?.key,
          insurance_company: formData.healthInsuranceCompany || '',
          health_insurance_number: formData.healthInsuranceNumber || '',
          weight: formData.weight,
          height: formData.height,
        });

        if (res) {
          showNotification(
            {
              type: 'success',
              text: 'Daten erfolgreich gespeichert',
            },
            3000,
          );
        }

        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <h3 className="mb-2">Geburtstag</h3>
          <div className="grid mb-6 grid-cols-3 gap-2">
            <Dropdown
              error={!!(formikProps.touched.birthday && formikProps.errors.birthday)}
              name="birthday"
              options={birthdates}
              placeholder="Tag"
              selected={birthdates.find(
                ({ value }) => value + '' === formikProps.values.birthday + '',
              )}
            />
            <Dropdown
              error={!!(formikProps.touched.birthmonth && formikProps.errors.birthmonth)}
              name="birthmonth"
              options={MONTHS.map((value, index) => ({ key: index, value }))}
              placeholder="Monat"
              selected={MONTHS.map((value, index) => ({ key: index, value })).find(
                ({ value }) => value + '' === formikProps.values.birthmonth,
              )}
            />
            <Dropdown
              error={!!(formikProps.touched.birthyear && formikProps.errors.birthyear)}
              name="birthyear"
              options={years}
              placeholder="Jahr"
              selected={years.find(({ value }) => value + '' === formikProps.values.birthyear + '')}
            />
          </div>
          <h3 className="mb-2">Größe/Gewicht/Geschlecht</h3>
          <div className="grid mb-6 grid-cols-2 sm:grid-cols-3 gap-2">
            <Input
              error={!!formikProps.errors.height}
              id="height"
              name="height"
              placeholder="Größe"
              postFix="cm"
              type="number"
            />
            <Input
              error={!!formikProps.errors.weight}
              id="weight"
              name="weight"
              placeholder="Gewicht"
              type="number"
              postFix="kg"
            />
            <Dropdown
              name="gender"
              error={!!(formikProps.touched.gender && formikProps.errors.gender)}
              options={GENDER}
              selected={GENDER.find(({ key }) => key === patient?.gender)}
              placeholder="Geschlecht"
            />
          </div>
          <h3 className="mb-2">Versicherung</h3>
          <div className="grid mb-2 grid-cols-2 gap-2">
            <Input
              id="healthInsuranceCompany"
              name="healthInsuranceCompany"
              placeholder="Krankenkasse (optional)"
            />
            <Dropdown
              name="healthInsuranceType"
              error={!!formikProps.errors.healthInsuranceType}
              options={INSURANCE}
              placeholder="Privat/gesetzlich"
              selected={INSURANCE.find(({ key }) => key === patient?.insurance_type)}
            />
          </div>
          <Input
            id="healthInsuranceNumber"
            name="healthInsuranceNumber"
            placeholder="Krankenversichertennummer (optional)"
          />
          <div className="mt-6">
            <Button type="submit" disabled={formikProps.isSubmitting}>
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
