import Title from 'components/util/Title';
import MyDocuments from 'components/Documents/MyDocuments';
import PageContentWrapper from 'components/PageContentWrapper';

/**
 * Page representing the documents section of the user
 * @returns ReactElement
 */
export default function Documents() {
  return (
    <PageContentWrapper>
      <Title title="Dokumente" />
      <MyDocuments />
    </PageContentWrapper>
  );
}
