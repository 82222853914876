import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_cross.svg';
import { useAuthContext } from 'components/util/useAuth';
import { routes } from 'components/Navigation/Sidebar';
import { NavLink } from 'react-router-dom';
import UserIcon from './UserIcon';

interface MobileMenuProps {
  close: () => void;
}

export default function MobileMenu({ close }: MobileMenuProps) {
  const { logout } = useAuthContext();
  const portalDiv = document.getElementById('portal') || document.createElement('div');

  const onLogout = () => {
    window.dataLayer.push({
      event: 'patienten-logout',
    });

    logout();
  };

  useEffect(() => {
    const root: any = document.querySelector('#root') || document.createElement('div');
    root.style.display = 'none';
    return () => {
      root.style.display = 'block';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="absolute top-0 left-0 w-full h-screen bg-swopa-primary-dark-blue text-swopa-primary-white z-40">
      <div className="flex flex-row mt-[35px] mx-[35px] justify-between items-center">
        <div className="flex flex-row items-center">
          <UserIcon />
          <div
            className="ml-4 flex items-center cursor-pointer hover:text-swopa-accent-green"
            onClick={onLogout}
          >
            <LogoutIcon className="mr-2" />
            <span>Abmelden</span>
          </div>
        </div>
        <CloseIcon className="cursor-pointer" onClick={close} />
      </div>
      <div className="flex flex-col sm:items-end sm:justify-between sm:flex-row mx-16 mt-16 sm:mt-24 sm:mx-[133px]">
        <div className="flex flex-col">
          <h1 className="mb-12 text-swopa-primary-white">Menü</h1>
          <nav>
            <ul>
              {routes.map(({ title, to, Icon }, index) => (
                <li key={index} className="w-full ">
                  <NavLink
                    className={(navData) =>
                      `flex flex-row justify-start py-4 cursor-pointer  ${
                        navData.isActive ? 'text-swopa-accent-green' : 'text-swopa-primary-white'
                      }`
                    }
                    onClick={close}
                    to={to}
                  >
                    <Icon className="mr-5" />
                    <span>{title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>,
    portalDiv,
  );
}
