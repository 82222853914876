import { useMutation } from '@tanstack/react-query';
import http from 'components/util/http';
import { Form, Formik } from 'formik';
import Button from 'styles/Button';
import FormikCheckbox from 'styles/Signup/FormikCheckbox';

const THERAPY_POINTS = [
  'Medizinisches Cannabis ist keine Therapie der ersten Wahl, sondern in der Regel ein so genannter Off-Label-Use',
  'Für medizinisches Cannabis sind keine festen Indikationen festgelegt',
  'Medizinisches Cannabis kann zum Einsatz kommen, wenn andere leitliniengerechte Therapien keinen Erfolg gezeigt haben',
];

const DOSAGE_POINTS = [
  'Bis zum Erreichen einer stabilen Tagesdosis empfehlen wir nicht Auto zu fahren',
  'Lagerung muss grundsätzlich den Zugriff Dritter verhindern',
];

const USAGE_POINTS = [
  'Extrakte haben einen verzögerten Wirkeintritt und Fluten erst nach circa ein bis 2 Stunden an, die Wirkdauer beträgt bis zu 8 Stunden',
  'Anwendungshinweise der Apotheke beachten',
];

const FLOWER_POINTS = [
  'Es empfiehlt sich die Anwendung durch einen Verdampfer da rauchen med. nicht vertretbar ist',
  'Wirkeintritt bei Inhalation ist sofort',
  'Anwendungshinweise der Apotheke beachten',
];

const SIDE_EFFECTS_POINTS = [
  'Trockene Schleimhäute wie zum Beispiel Augen oder Mund',
  'Lokale und systemische allergische Reaktionen',
  'Müdigkeit, verminderte Leistungsfähigkeit',
  'Erhöhter Appetit, Gewichtszunahme',
  'Kardiovaskuläre Auswirkungen, wie zum Beispiel Schwindel, erhöhte Herzfrequenz und Blutdruckabfall',
  'Veränderte oder verstärkte Wirkung und Dauer bei zum Beispiel Leber oder Nierenschädigungen',
  'Psychosen, Panik, Halluzinationen, Angst Zustände',
  'Sucht, Abhängigkeit, Toleranz Entwicklung',
  'Störung des Hormonhaushalts',
  'Veränderte Sinneswahrnehmungen',
  'Hinweise der Apotheke beachten',
];

const INTERPLAY_POINTS = [
  'Verstärkung oder Reduktion der Wirkung von anderen Medikamenten, wie zum Beispiel Medormin, Beruhigungsmittel, Schlafmittel, Blutdruckmitteln, Muskelrelxaantien, Schmerzmitteln, Antidepressiva, Antikoagulation, Neuroleptika, und Weitere',
];

const ABILYTI_TO_DRIVE_POINTS = [
  'Die Verkehrstüchtigkeit durch die Anwendung von medizinischen Cannabis ist grundsätzlich eingeschränkt beziehungsweise nicht gegeben',
  'Die Fahrsicherheit ist beeinträchtigt unter anderem durch Konzentrationseinschränkungen Schwindel geringere Reaktionsvermögen',
  'Es wird empfohlen die Dauer der Eindosierung zu beenden und erst danach wieder aktiv am Straßenverkehr teilzunehmen',
  'Es besteht eine Verpflichtung zur Selbstüberprüfung vor jedem Fahrtantritt, Sie tragen grundsätzlich selbst die Verantwortung für die Sicherheit',
  'Wir empfehlen einen Patienten Ausweis, eine Kopie eines Rezeptes, oder einen sonstigen Nachweis mitzuführen',
];

const OTHER_POINTS = [
  'Auf Reisen kann die Medikation nur eingeschränkt mitgeführt werden',
  'Es gilt das jeweils örtliche Recht',
  'Missbräuchlicher Konsum/bei Konsum von anderen Arzneimitteln/Betäubungsmitteln, welche nicht verordnet worden, führt zum Therapie Ausschluss',
  'Wiedervorstellung und Therapiekontrolle nach 30 Tagen. Wiedervorstellung innerhalb oder bis 30 Tage per Video-Telefonie möglich',
];

const CONSENT_POINTS = [
  {
    title: 'Aufklärungsbogen - Medizinischem Cannabis Therapie',
    options: THERAPY_POINTS,
  },
  {
    title: 'Anwendung und Dosierung',
    options: DOSAGE_POINTS,
  },
  {
    title: 'Anwendung von Extrakten',
    options: USAGE_POINTS,
  },
  {
    title: 'Anwendung von Blüten',
    options: FLOWER_POINTS,
  },
  {
    title: 'Nebenwirkungen',
    options: SIDE_EFFECTS_POINTS,
  },
  {
    title: 'Wechselwirkungen',
    options: INTERPLAY_POINTS,
  },
  {
    title: 'Verkehrstüchtigkeit',
    options: ABILYTI_TO_DRIVE_POINTS,
  },
  {
    title: 'Sonstiges',
    options: OTHER_POINTS,
  },
];

const sectionHeadingStyle = 'text-xl md:text-2xl font-semibold text-swopa-primary-dark-blue mb-2';

type InformationSheetValues = {
  acceptedTerms: boolean;
};

type InformationSheetProps = {
  onContinue: () => void;
  className: string;
};

export default function InformationSheet({ onContinue, className }: InformationSheetProps) {
  const submit = useMutation({
    mutationFn: (val: InformationSheetValues) =>
      http.patch('/medical/patients/me/', {
        is_info_sheet_accepted: val.acceptedTerms,
      }),
    onSuccess: () => onContinue(),
    onError: (error) => {
      console.log(error);
    },
  });
  return (
    <Formik<InformationSheetValues>
      initialValues={{
        acceptedTerms: false,
      }}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={(val) => submit.mutate(val)}
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit} className={className}>
          <div className="px-4 space-y-5 max-w-2xl">
            {CONSENT_POINTS.map(({ title, options }) => (
              <div key={title}>
                <p className={sectionHeadingStyle}>{title}:</p>
                <BulletPoints points={options} />
              </div>
            ))}
            <FormikCheckbox
              name="acceptedTerms"
              id="acceptedTerms"
              label="Ich bestätige, dass alle meine Angaben wahrheitsgemäß sind.
Ich nehme den Aufklärungsbogen zur Kenntnis und bin damit einverstanden."
              type="checkbox"
            />
            <Button type="submit" disabled={!values.acceptedTerms} loading={submit.isPending}>
              Speichern
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

type BulletPointProps = {
  points: string[];
};

function BulletPoints({ points }: BulletPointProps) {
  return (
    <ul className="list-disc leading-6 text-swopa-primary-dark-blue mb-5 space-y-1 ml-4">
      {points.map((point) => (
        <li key={point}>{point}</li>
      ))}
    </ul>
  );
}
