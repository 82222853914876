import Header from 'components/Navigation/Header';
import { RequireAuth } from 'components/RequireAuth';
import { OnboardingNavigation } from 'providers/onboarding-navigation';

interface SignupLayoutProps {
  children: React.ReactNode;
}

export default function HeyflowLayout({ children }: SignupLayoutProps) {
  return (
    <RequireAuth>
      <OnboardingNavigation>
        <div className="w-screen h-auto lg:h-[calc(100vh-80px)] xl:h-screen flex flex-col lg:flex-row overflow-x-hidden mt-20 xl:mt-0">
          <div className="lg:overflow-y-auto w-full">
            <Header isOnboarding simpleNavigation isFragebogen />
            <div id="iframe-wrapper" className="max-w-[865px] m-auto relative">
              {children}
            </div>
          </div>
        </div>
      </OnboardingNavigation>
    </RequireAuth>
  );
}
