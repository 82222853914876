import { useState, useEffect, useMemo } from 'react';

import usePatient from 'components/Navigation/usePatient';
import { useCurrentTherapyState } from 'components/Therapy/usePrescriptions';
import usePrescriptions from 'components/Therapy/usePrescriptions';

export const PREASSESSMENT_NEGATIVE = 'preassessmentNegative';
export const PREASSESSMENT_DONT_EXIST = 'preassessmentDontExist';
export const PREASSESSMENT_UNDER_EVALUTATION = 'preassessmentUnderEvaluation';
export const READY_TO_BOOK_FIRST_APPOINTMENT = 'readyToBookFirstAppointment';
export const SHOW_FIRST_BOOKING = 'showFirstBooking';
export const SHOW_FOLLOW_UP_BOOKING = 'showFollowupBooking';

export default function usePatientStatus() {
  const [patientStatus, setPatientStatus] = useState('');
  const { data } = usePatient() as any;
  const therapyState = useCurrentTherapyState();
  const { data: prescriptionData } = usePrescriptions();
  const prescriptions = prescriptionData?.filter(
    (prescription) => prescription.appointment.state !== 'cancelled',
  );

  const showPreassessmentNegative = useMemo(() => {
    if (data?.pre_assessment === 'negative') {
      return true;
    }
    return false;
  }, [data?.pre_assessment]);

  const showPreassessmentDontExist = useMemo(() => {
    if (data?.pre_assessment === '') {
      return true;
    }
    return false;
  }, [data?.pre_assessment]);

  const showPreassessmentUnderEvaluation = useMemo(() => {
    if (
      data?.pre_assessment_status === 'pending' ||
      data?.pre_assessment === 'submitted' ||
      data?.pre_assessment === 'consultation'
    ) {
      return true;
    }
    return false;
  }, [data?.pre_assessment]);

  const showReadyToBookFirstAppointment = useMemo(() => {
    if (data?.pre_assessment === 'positive' && prescriptions?.length === 0) return true;
    if (data?.pre_assessment === 'positive' && prescriptions) {
      if (prescriptions.length > 0) {
        const temp = prescriptions.sort(
          ({ appointment: a }, { appointment: b }) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )[0];
        if (temp.appointment.format === 'in_person' && temp.appointment.state === 'not_attended') {
          return true;
        }
      }
    }
    return false;
  }, [data?.pre_assessment, prescriptions]);

  const showFirstBooking = useMemo(() => {
    if (therapyState === 'started_continued') return false;
    if (data?.pre_assessment === 'positive' && prescriptions) {
      if (prescriptions.length > 0) {
        const temp = prescriptions.sort(
          ({ appointment: a }, { appointment: b }) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )[0];
        if (temp.appointment.state === 'created' || temp.appointment.state === 'not_attended') {
          return true;
        }
      }
    }
    return false;
  }, [therapyState, data?.pre_assessment, prescriptions]);

  const showFollowupBooking = useMemo(() => {
    if (therapyState === 'started_continued') return true;
    if (prescriptions && prescriptions.length > 0) {
      const temp = prescriptions.sort(
        ({ appointment: a }, { appointment: b }) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      )[0];
      // video_consultation
      if (
        temp.appointment.format === 'video_consultation' &&
        (temp.appointment.state === 'created' ||
          temp.appointment.state === 'not_attended' ||
          temp.appointment.state === 'attended')
      ) {
        return true;
        // in_person
      } else if (temp.appointment.state === 'attended') {
        return true;
      }
    }
    return false;
  }, [therapyState, prescriptions]);

  useEffect(() => {
    if (showPreassessmentNegative) {
      return setPatientStatus(PREASSESSMENT_NEGATIVE);
    }
    if (showPreassessmentDontExist) {
      return setPatientStatus(PREASSESSMENT_DONT_EXIST);
    }
    if (showPreassessmentUnderEvaluation) {
      return setPatientStatus(PREASSESSMENT_UNDER_EVALUTATION);
    }
    if (showReadyToBookFirstAppointment) {
      return setPatientStatus(READY_TO_BOOK_FIRST_APPOINTMENT);
    }
    if (showFirstBooking) {
      return setPatientStatus(SHOW_FIRST_BOOKING);
    }
    if (showFollowupBooking) {
      return setPatientStatus(SHOW_FOLLOW_UP_BOOKING);
    }
  }, [data?.pre_assessment, therapyState, prescriptions]);

  return { patientStatus };
}
