import Container from 'styles/Prescription/Container';
import { ReactComponent as PlasterIcon } from 'assets/icons/plaster.svg';
import { Link } from 'react-router-dom';

interface StepFailureProps {
  text: string;
  link?: string;
}

function ExplanationLink({ link }: any) {
  //TODO:Link to explanation
  return (
    <div className="flex justify-center items-center mb-2">
      <Link to={link} className="text-swopa-secondary-grey underline cursor-pointer">
        Erfahren Sie den Grund hierfür beim Kundenservice
      </Link>
    </div>
  );
}

export default function StepFailure({ text, link }: StepFailureProps) {
  return (
    <Container additional={<ExplanationLink link={link} />}>
      <div className="flex flex-col items-center justify-center space-y-2">
        <PlasterIcon className="text-swopa-warning-red" />
        <span className="text-swopa-primary-dark-blue text-center">{text}</span>
      </div>
    </Container>
  );
}
