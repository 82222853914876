import http from 'components/util/http';
import { useQuery } from '@tanstack/react-query';

interface FAQItem {
  id: number;
  answer: string;
  question: string;
  category: string;
}

export default function useOnboarding({ category }: { category: string }) {
  return useQuery<Array<FAQItem>, Error>({
    queryKey: ['faq', category],
    queryFn: async () => {
      const { results } = await http.get(
        `/cms/models/frequently-asked-questions/?category=${category}`,
      );
      if (!results || !results.length) return [];
      return results;
    },
  });
}
