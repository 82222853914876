import { Helmet } from 'react-helmet-async';

interface TitleProps {
  title: string;
}

export default function Title({ title }: TitleProps) {
  return (
    <Helmet>
      <title>{title} - Enmedify</title>
    </Helmet>
  );
}
