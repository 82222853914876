import { Field, useField } from 'formik';
import { useState } from 'react';

interface InputProps {
  id?: string;
  type?: string;
  name: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  max?: number;
}

export default function Textarea(props: InputProps) {
  const [charsLeft, setCharsLeft] = useState(props.max);
  const [_field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <div className={`relative w-full ${props.className ? props.className : ''}`}>
      <textarea
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        maxLength={props.max}
        rows={5}
        value={value}
        className={`appearance-none border h-full resize-none bg-swopa-hover-grey ${
          props.error
            ? 'border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red'
            : 'border-swopa-border text-swopa-primary-dark-blue placeholder:text-swopa-secondary-grey'
        } rounded w-full py-3 px-4  focus:outline-swopa-primary-dark-blue`}
        onChange={(e: any) => {
          if (props.max) {
            setCharsLeft(props.max - e.target.value.length);
          }
          setValue(e.target.value);
        }}
      />
      {props.max && (
        <p className="absolute bottom-2 right-2 text-swopa-secondary-grey">
          {charsLeft} Zeichen übrig
        </p>
      )}
      {props.error && props.errorMessage && (
        <span className="text-swopa-warning-red text-field-validation mt-2">
          {props.errorMessage}
        </span>
      )}
    </div>
  );
}
