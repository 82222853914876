import { useState } from 'react'
import Button from '../../styles/Button'
import { ReactComponent as GreenCheck } from 'assets/icons/green_check.svg';
import usePatient from 'components/Navigation/usePatient';
import { useAuthContext } from 'components/util/useAuth';

export default function WelcomeModal() {

  const { authenticatedUser, updateUser } = useAuthContext();
  const { data } = usePatient();
  const [visible, setVisible] = useState(authenticatedUser && !authenticatedUser.last_login);

  const getTitle = (gender: string | undefined) => {
    if (gender === 'm') {
      return 'Herr';
    }
    else if (gender === 'w') {
      return 'Frau';
    } else {
      return data?.first_name
    }
  };

  const onClose = async () => {
    setVisible(false);
    await updateUser({
      last_login: new Date().toISOString(),
    })
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <div className="relative z-[9999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative p-[40px] transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 flex items-center flex-col">
                <div className="mb-[20px]">
                 <GreenCheck />
                </div>
                <div className="text-center">
                  <h2 className="text-4xl text-[#2D4370] leading-[1.25]">Willkommen <br/> {getTitle(data?.gender)} {data?.last_name}</h2>
                </div>
                <div className="mt-[5px] mb-[40px] text-center">
                  <div className="mt-2">
                    <p className="text-[14px] text-[#898D8D] leading-[1.6]">Ihr Enmedify-Konto wurde erfolgreich erstellt. Wir freuen uns, Sie als Patient begrüßen zu dürfen.</p>
                  </div>
                </div>
              </div>
              <Button onClick={() => onClose()}>Weiter</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}





