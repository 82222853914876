import http from 'components/util/http';
import { useQuery } from '@tanstack/react-query';

interface Doctor {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  street: string;
  postal_code: number;
  city: string;
  country_code: string;
  phone_number: string;
  occupational_title: string;
}

interface Practice {
  id: number;
  registration_number: string;
  street: string;
  postal_code: string;
  city: string;
  country_code: string;
}

export type AppointmentState = 'created' | 'attended' | 'not_attended' | 'cancelled';

interface Appointment {
  id: number;
  cancel_url: string;
  modify_url: string;
  created_at: Date;
  doctor: Doctor | null;
  practice: Practice | null; // TODO: clarify with backend if `null` is actually an option (imho it shouldn't)
  patient: number;
  video_consultation_tan: string;
  video_consultation_patient_url: string;
  format: 'in_person' | 'video_consultation';
  state: AppointmentState;
  therapy_state:
    | 'not_set'
    | 'waiting_for_feedback'
    | 'started_continued'
    | 'denied'
    | 'paused'
    | 'finished';
}

interface Prescription {
  id: number;
  patient: number;
  serial_number: string;
  date: Date;
  amount: number;
  unit: 'gram' | 'milliliter' | 'piece';
  prescription_number: string;
  appointment: number | null;
  indica_sativa: 'indica' | 'sativa' | 'hybrid' | null;
  pharmaceutical_free_text: string;
  pharmaceutical_type: string;
  procedure: string;
  reason_to_change_preparation: string;
  share_of_cbd: number;
  share_of_thc: number;
  title: string;
}

interface FullPrescription {
  appointment: Appointment;
  prescription?: Prescription[];
}
/**
 * Hook to check current therapy state
 * @returns bool
 */
export function useCurrentTherapyState() {
  const { data: prescriptions } = usePrescriptions();
  if (!prescriptions || !prescriptions.length) return null;
  return prescriptions.sort(
    (a, b) =>
      new Date(b.appointment.created_at).getTime() - new Date(a.appointment.created_at).getTime(),
  )[0].appointment.therapy_state;
}

/**
 * Hook that fetches all appointments and prescriptions.
 * Matches appointments and prescriptions based on appointmentId
 */
export default function usePrescriptions() {
  return useQuery<Array<FullPrescription>, Error>({
    queryKey: ['prescriptions'],
    queryFn: async () => {
      const { results: appointments } = await http.get('/medical/appointments/');

      if (!appointments || !appointments.length) return [];
      const { results: prescriptions } = await http.get('/medical/prescriptions/');

      if (!prescriptions || !prescriptions.length) {
        return appointments
          .filter((val: any) => val.state !== 'cancelled')
          .map((appointment: Appointment) => ({
            appointment,
            prescription: undefined,
          }));
      } else {
        return appointments.map((appointment: Appointment) => ({
          appointment,
          prescription: prescriptions.filter(
            (prescription: Prescription) => prescription.appointment === appointment.id,
          ),
        }));
      }
    },
  });
}
