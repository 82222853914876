import { useFormikContext } from 'formik';
import { object, ref, string } from 'yup';
import PasswordInput from 'styles/FormikPasswordInput';
import http from 'components/util/http';

export const PASSWORD = 'password';
export const PASSWORD_REPEAT = 'passwordRepeat';

export type PasswordRepeatSubFormFields = {
  [PASSWORD]: string;
  [PASSWORD_REPEAT]: string;
};

export const defaultValues: PasswordRepeatSubFormFields = {
  [PASSWORD]: '',
  [PASSWORD_REPEAT]: '',
};

const PASSWORD_REQUIRED_ERROR = 'Bitte geben Sie ein Passwort ein.';
const PASSWORD_CRITERIA_ERROR = 'Bitte geben Sie ein Passwort ein das die Kriterien erfüllt.';
const PASSWORDS_NOT_MATCHING_ERROR = 'Die Passwörter stimmen nicht überein.';

export const validationSchema = object({
  [PASSWORD]: string()
    .required(PASSWORD_REQUIRED_ERROR)
    .test(PASSWORD, PASSWORD_CRITERIA_ERROR, (password: string | undefined, { createError }) => {
      if (password) {
        return http
          .post('/auth/users/validate-password/', {
            password,
          })
          .then(() => {
            return true;
          })
          .catch((res: any) => {
            return createError({ message: res.error.join('\n') });
          });
      }
      return false;
    }),
  [PASSWORD_REPEAT]: string()
    .required(PASSWORD_REQUIRED_ERROR)
    .oneOf([ref(PASSWORD), null], PASSWORDS_NOT_MATCHING_ERROR),
});

export const PasswordRepeatSubForm = () => {
  const { touched, errors } = useFormikContext<PasswordRepeatSubFormFields>();
  return (
    <>
      <PasswordInput
        id={PASSWORD}
        name={PASSWORD}
        placeholder="Neues Passwort"
        error={
          !!(touched[PASSWORD] && errors[PASSWORD]) ||
          // the validation for equality is on the second input only,
          // but if they don't match then both inputs should be highlighted
          errors[PASSWORD_REPEAT] === PASSWORDS_NOT_MATCHING_ERROR
        }
        className="mb-2"
      />
      {errors && errors[PASSWORD] && (
        <ul className="list-disc pl-5 text-swopa-warning-red text-field-validation space-y-1 mb-2">
          {errors[PASSWORD]?.split('\n').map((msg: string, index) => (
            <>{msg && msg.length > 1 && <li key={index}>{msg}</li>} </>
          ))}
        </ul>
      )}
      <PasswordInput
        id={PASSWORD_REPEAT}
        name={PASSWORD_REPEAT}
        placeholder="Neues Passwort wiederholen"
        error={!!(touched[PASSWORD_REPEAT] && errors[PASSWORD_REPEAT])}
        className="mb-2"
      />
      {errors &&
        errors[PASSWORD_REPEAT] &&
        errors[PASSWORD_REPEAT] === PASSWORDS_NOT_MATCHING_ERROR && (
          <ul className="list-disc pl-5 text-swopa-warning-red text-field-validation space-y-1 mb-2">
            <li>{PASSWORDS_NOT_MATCHING_ERROR}</li>
          </ul>
        )}
      <div className="mb-6 text-swopa-secondary-grey">
        <span>Ihr Passwort muss aus mindestens 8 Zeichen bestehen und eine Zahl beinhalten.</span>
      </div>
    </>
  );
};
