import classnames from 'classnames';

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode;
  bgColor?: 'green' | 'blue' | 'grey';
  disabled?: boolean;
  fit?: boolean;
  size?: string;
  loading?: boolean;
}

export default function Button({
  children,
  bgColor = 'green',
  disabled = false,
  fit = false,
  size = 'normal',
  className,
  loading,
  ...props
}: ButtonProps) {
  const isDisabled = disabled || loading;
  return (
    <button
      disabled={isDisabled}
      className={classnames(
        'font-radikal rounded-[4px] w-full uppercase flex items-center justify-center',
        {
          'bg-swopa-accent-green text-swopa-primary-dark-blue hover:bg-swopa-accent-green-hover':
            bgColor === 'green' && !isDisabled,
          'bg-swopa-secondary-light-blue hover:bg-swopa-secondary-light-blue-hover text-white':
            bgColor === 'blue' && !isDisabled,
          'bg-swopa-grey-2': bgColor === 'grey' && !isDisabled,
          'bg-swopa-grey-dark': bgColor === 'green' && isDisabled,
          'bg-swopa-secondary-light-blue-hover': bgColor === 'blue' && isDisabled,
          'w-full': !fit,
          'w-fit': fit,
          'px-10 py-3': size === 'normal',
          'px-6 py-1 ': size === 'small',
        },
        className,
      )}
      {...props}
    >
      {loading && (
        <div className="flex items-center justify-center mr-2">
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            />
          </svg>
        </div>
      )}
      {children}
    </button>
  );
}
