import { Prices } from './types';

const prices: Prices = {
  first_appointment_online: {
    DE: '75€',
    CH: '129 CHF',
  },
  first_appointment_clinic: {
    DE: '109€',
    CH: '129 CHF',
  },
  follow_up_online: {
    DE: '39€',
    CH: '99 CHF',
  },
  no_show_fee: {
    DE: '19€',
    CH: '49 CHF',
  },
};

export default prices;
