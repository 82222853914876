import consentConfig from './config';
import * as CookieConsent from 'vanilla-cookieconsent';
export default async function GoogleAnalytics() {
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]) {
    window.dataLayer.push(args);
  }

  function sendPreferences() {
    // Google Analytics
    const categories = {
      analytics_storage: 'analytics',
      functionality_storage: 'necessary',
      personalization_storage: 'personalization',
      security_storage: 'necessary',
      ad_storage: 'marketing',
      ad_user_data: 'marketing',
      ad_personalization: 'marketing',
    };

    const consents = Object.entries(categories).reduce((acc, [key, category]) => {
      //@ts-ignore
      acc[key] = CookieConsent.acceptedCategory(category) ? 'granted' : 'denied';
      return acc;
    }, {});

    gtag('consent', 'update', consents);

    window.dataLayer.push({
      event: 'cc_global_status',
      cookies: CookieConsent.getUserPreferences().acceptedCategories.join(','),
    });

    ['analytics', 'personalization', 'marketing', 'necessary'].forEach((category) => {
      if (CookieConsent.acceptedCategory(category)) {
        const eventMap = {
          analytics: 'cc_analytics',
          personalization: 'cc_personalization',
          marketing: 'cc_marketing',
          necessary: 'cc_functionality',
        };
        window.dataLayer.push({
          //@ts-ignore
          event: eventMap[category],
        });
      }
    });
  }

  gtag('consent', 'default', {
    analytics_storage: 'denied',
    functionality_storage: 'granted',
    personalization_storage: 'denied',
    security_storage: 'granted',
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
  });

  gtag('set', 'ads_data_redaction', false);
  gtag('set', 'url_passthrough', true);
  gtag('js', new Date());

  (function (w, d, s, l) {
    // @ts-ignore
    w[l] = w[l] || [];
    // @ts-ignore
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = <HTMLBaseElement>d.getElementsByTagName(s)[0],
      j = <HTMLScriptElement>d.createElement(s);
    j.async = true;
    j.src = `${process.env.REACT_APP_GOOGLE_TAG_SERVER_SIDE_URL}`;
    f.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer');

  CookieConsent.run({
    ...consentConfig,
    onConsent: () => {
      sendPreferences();
    },
    onChange: () => {
      sendPreferences();
    },
  });
}
