import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import Container from 'styles/Prescription/Container';

interface WaitingProps {
  text: string;
}

export const waitingContainerClasses = `flex flex-col md:flex-row justify-center items-center text-swopa-primary-dark-blue text-center md:text-left space-y-2 md:space-y-0 md:space-x-2`;

export default function Waiting({ text, children }: React.PropsWithChildren<WaitingProps>) {
  return (
    <Container>
      <div className={waitingContainerClasses}>
        <ClockIcon />
        <span>{text}</span>
      </div>
      {children}
    </Container>
  );
}
