import { useState, useEffect, useMemo, useRef } from 'react';
import Box from 'styles/Box';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import http from 'components/util/http';
import axios from 'axios';
import { useNotificationContext } from 'components/Notification';

interface DocumentUploadProps {
  filename: string;
  asset: string;
  onCancel: () => void;
  onComplete: () => void;
}

export default function DocumentUpload({
  filename,
  asset,
  onCancel,
  onComplete,
}: DocumentUploadProps) {
  const { showNotification } = useNotificationContext();
  const [progress, setProgress] = useState(0);
  const [finished, setFinished] = useState(false);
  const documentPosted = useRef(false);

  const cancelToken = useMemo(() => {
    return axios.CancelToken.source();
  }, []);

  useEffect(() => {
    if (documentPosted.current === false) {
      const config = {
        cancelToken: cancelToken.token,
        onUploadProgress: (progressEvent: any) =>
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      };
      http.post('/medical/documents/', { asset, name: filename }, config).then(
        (res) => {
          setFinished(true);
          onComplete();
        },
        () => {
          showNotification(
            {
              type: 'error',
              text: `Beim Upload von ${filename} ist ein Fehler aufgetreten`,
            },
            10000,
          );
        },
      );
      return () => {
        documentPosted.current = true;
      };
    }
    // this useEffect is not specifying all dependencies on purpose:
    // We don't want to re-run it if a callback changes because that would upload the same file twice
  }, [asset, filename]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box border={finished ? 'green' : undefined}>
      <div className={`${finished ? 'bg-transparent' : 'bg-swopa-border'} relative`}>
        <div className="relative flex py-[14px] px-4 sm:px-6 z-10 sm:flex-row justify-between sm:items-center">
          <div className="grid">
            <span className="font-radikal text-swopa-primary-dark-blue">{filename}</span>
            <span
              className={`${
                finished ? 'text-swopa-primary-dark-blue' : 'text-swopa-secondary-grey'
              } `}
            >
              {finished ? 'Die Datei wurde erfolgreich hochgeladen.' : 'Datei wird hochgeladen ...'}
            </span>
          </div>
          {progress !== 100 ? (
            <CancelIcon
              className="text-swopa-warning-red cursor-pointer"
              onClick={() => {
                cancelToken.cancel('Upload cancelled');
                onCancel();
              }}
            />
          ) : (
            <CheckIcon className="text-swopa-accent-green w-6 h-6" />
          )}
        </div>
        <div
          style={{ right: 100 - progress + '%' }}
          className={`absolute rounded-[4px] ${
            finished ? 'bg-swopa-accent-green opacity-[0.08]' : 'bg-white'
          } top-0 z-0 left-0 bottom-0`}
        ></div>
      </div>
    </Box>
  );
}
