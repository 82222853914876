import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface OnboardingNavigationContextType {
  step: number;
  setStep: (value: number) => void;
}

const OnboardingNavigationContext = createContext<OnboardingNavigationContextType | undefined>(
  undefined,
);

export const OnboardingNavigation = ({ children }: { children: ReactNode }) => {
  const [step, setStep] = useState(-1);

  return (
    <OnboardingNavigationContext.Provider value={{ step, setStep }}>
      {children}
    </OnboardingNavigationContext.Provider>
  );
};

export const useOnboardingNavigation = () => {
  const context = useContext(OnboardingNavigationContext);

  if (!context) {
    return { step: -1, setStep: () => {} };
    throw new Error('useOnboardingNavigation must be used within an OnboardingNavigationContext');
  }
  return context;
};
