import PageContentWrapper from 'components/PageContentWrapper';
import { Route, Routes } from 'react-router-dom';
import Settings from './Settings';
import DeleteAccount from './DeleteAccount';

export default function SettingsIndexPage() {
  return (
    <PageContentWrapper>
      <Routes>
        <Route index element={<Settings />} />
        <Route path={'konto-loeschen'} element={<DeleteAccount />} />
      </Routes>
    </PageContentWrapper>
  );
}
