import { useRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';

type Props = {
  options: Array<any>;
  handleChange: (e: any) => any;
  selected: string;
  name: string;
};

export default function DocumentCategorySelect(props: Props) {
  const { options, handleChange, selected, name } = props;
  const selectElementRef = useRef() as any;

  return (
    <div className="relative mb-2 sm:mb-0">
      <select
        id="documentCategorySelect"
        ref={selectElementRef}
        onChange={handleChange}
        name={name}
        value={selected}
        className={classNames(
          `w-full sm:max-w-[300px] truncate bg-transparent text-swopa-primary-dark-blue outline-none text-xs appearance-none px-2 pr-10 border border-swopa-passive p-2 rounded`,
          {
            '!text-swopa-secondary-grey': selected === '',
          },
        )}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ArrowDown className="absolute top-[50%] translate-y-[-50%] right-3 stroke-swopa-secondary-grey pointer-events-none" />
    </div>
  );
}
