export function Grid({ children }: any) {
  return <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4">{children}</div>;
}

interface GridItemProps {
  title: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  info?: string;
}

export function GridItem({ title, children, icon, info }: GridItemProps) {
  return (
    <div className="flex flex-col py-2 border-b border-b-swopa-border md:second-to-last:pb-0 md:last:pb-0 last:border-none sm:second-to-last:border-none">
      <div className="flex justify-between items-start">
        <span className="text-swopa-secondary-grey">{title}</span> {icon}
      </div>
      <span className="text-swopa-primary-dark-blue">{children}</span>
      {info && <span className="text-swopa-secondary-grey">{info}</span>}
    </div>
  );
}

export default Grid;
