import { useMemo } from 'react';
import { type CountryCode } from '../localizations/types';
import marketingPageLinks from '../localizations/marketingPageLinks';

type LocalizedMarketingPageLinks = {
  marketing_page: string;
  impressum: string;
  privacy_policy: string;
  general_terms: string;
};

export default function useLocalizedMarketingPageLinks(): LocalizedMarketingPageLinks {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;
  const MARKETING_WEBSITE_BASE_URL = process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL;

  return useMemo((): LocalizedMarketingPageLinks => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    if (MARKETING_WEBSITE_BASE_URL === undefined) {
      throw new Error('Marketing website base url not set, please specify it.');
    }

    return {
      marketing_page: MARKETING_WEBSITE_BASE_URL,
      impressum: MARKETING_WEBSITE_BASE_URL + marketingPageLinks.impressum[LOCALIZATION],
      general_terms: MARKETING_WEBSITE_BASE_URL + marketingPageLinks.general_terms[LOCALIZATION],
      privacy_policy: MARKETING_WEBSITE_BASE_URL + marketingPageLinks.privacy_policy[LOCALIZATION],
    };
  }, [LOCALIZATION, MARKETING_WEBSITE_BASE_URL]);
}
