import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function PatientInvite() {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    if (params.get('uid') && params.get('token')) {
      navigate(`/passwort-setzen/?uid=${params.get('uid')}&token=${params.get('token')}`);
    }
  }, [params, navigate]);

  return (
    <div className="h-screen flex justify-center items-center">
      <h2>Fehlerhafter Link</h2>
    </div>
  );
}
