const en = {
  consentModal: {
    label: 'Cookie consent',
    title: 'Cookies',
    description:
      'This website stores cookies on your computer. These cookies are used to improve our website and provide more personalized services to you, both on this website and through other media. To find out more about the cookies we use, see our Privacy policy.',
    acceptAllBtn: 'Accept all',
    acceptNecessaryBtn: 'Reject all',
    showPreferencesBtn: 'Manage preferences',
    footer: `<a href="${process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL}/datenschutz">Privacy policy</a>\n<a href="${process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL}/agb">AGB</a>`,
  },
  preferencesModal: {
    title: 'Consent preferences',
    acceptAllBtn: 'Accept all',
    acceptNecessaryBtn: 'Reject all',
    savePreferencesBtn: 'Save settings',
    closeIconLabel: 'Close modal',
    serviceCounterLabel: 'Service|Services',
    sections: [
      {
        title: 'Manage Consent Preferences',
        description: `This website stores cookies on your computer. These cookies are used to improve our website and provide more personalized services to you, both on this website and through other media. To find out more about the cookies we use, see our <a class="cc__link" href="${process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL}/datenschutz">Privacy policy</a>.`,
      },
      {
        title: 'Strictly necessary cookies <span class="pm__badge">Always enabled</span>',
        description:
          'These cookies are essential for you to browse the website and use its features. Usually they are only set in response to the actions made by regarding request for services, such as setting your privacy preferences or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not work then. These cookies do not store any personally identifiable information.',
        linkedCategory: 'necessary',
      },
      {
        title: 'Analytics cookies',
        description:
          'These cookies collect information about how you use a website, which pages you visited and which links you clicked on. None of this information can be used to identify you. It is all aggregated and, therefore, anonymized.',
        linkedCategory: 'analytics',
      },
      {
        title: 'User experience cookies',
        description:
          'These trackers help us to improve the quality of your user experience and enable interactions with external content, networks and platforms.',
        linkedCategory: 'personalization',
      },
      {
        title: 'Marketing cookies',
        description:
          'These cookies track your online activity to help us deliver more relevant advertising messages. They can be used by the third parties to build a profile of your interests based on browsing information by identifying your browser and terminal equipment.',
        linkedCategory: 'marketing',
      },
      {
        title: 'More information',
        description: `For any queries in relation to our policy on cookies and your choices, feel free to <a class="cc__link" href="${process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL}/contact">contact us</a>.`,
      },
    ],
  },
};

export default en;
