import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Step from './Step';
import CallToAction from './CallToAction';
import Circle from './Circle';
import {
  PREASSESSMENT_DONT_EXIST,
  PREASSESSMENT_UNDER_EVALUTATION,
  READY_TO_BOOK_FIRST_APPOINTMENT,
  SHOW_FOLLOW_UP_BOOKING,
} from 'hooks/usePatientStatus';
import Button from 'styles/Button';
import usePatient from 'components/Navigation/usePatient';
import useLocalizedPrices from '../../../hooks/useLocalizedPrices';
import { useBookingUrl } from '../../../providers/BookingUrl';

export default function InfoBanner({
  firstAppointmentUrl,
  followingAppointmentUrl,
  hasPrescriptions,
}: {
  firstAppointmentUrl: {
    inPerson: string;
    virtual: string;
  };
  followingAppointmentUrl: string;
  hasPrescriptions: boolean;
}) {
  const { data: patient } = usePatient();
  const { first_appointment_online, first_appointment_clinic, follow_up_online } =
    useLocalizedPrices();
  const { setBookingUrl } = useBookingUrl();

  const patientStatusData = useMemo(
    () => ({
      [PREASSESSMENT_DONT_EXIST]: {
        step: 1,
        status: PREASSESSMENT_DONT_EXIST,
        title: 'Fragebogen ausfüllen',
        description:
          'Mithilfe des Fragebogens überprüfen wir, ob Sie grundsätzlich für eine Cannabistherapie geeignet sind.',
        tooltip:
          'Der Fragebogen hilft unserem Ärzteteam, Ihre Situation besser zu verstehen. Damit können wir einschätzen, ob eine Therapie mit medizinischem Cannabis für Sie in Frage kommt. Weiterhin dient der Fragebogen als Vorbereitung für Ihren behandelnden Arzt bzw. Ihre behandelnde Ärztin.',
        ctaTitle: 'Fragebogen starten',
        ctaDescription: 'Nur 7 Minuten',
        theme: 'dark',
      },
      [PREASSESSMENT_UNDER_EVALUTATION]: {
        step: 1,
        status: PREASSESSMENT_UNDER_EVALUTATION,
        title: 'Fragebogen wird geprüft',
        description:
          'Sie können dem Arzt bereits vor Ihrem Ersttermin alle relevanten Dokumente per Upload bereitstellen.',
        tooltip:
          'Ihr Fragebogen zum medizinischen Screening befindet sich zurzeit in ärztlicher Prüfung. Sobald die Prüfung abgeschlossen ist, werden wir Sie per E-Mail informieren.',
        ctaTitle: 'Dokumente hochladen',
        ctaDescription: 'Befunde, Arztbriefe, etc.',
        theme: 'light',
      },
      [READY_TO_BOOK_FIRST_APPOINTMENT]: {
        step: 2,
        status: READY_TO_BOOK_FIRST_APPOINTMENT,
        title: 'Ersttermin buchen',
        description: `Der Termin zum ärztlichen Beratungsgespräch wird nach GOÄ berechnet. Ein Termin vor Ort kostet ${first_appointment_clinic} und ein online Termin ${first_appointment_online}.`,
        ctaTitle: 'Jetzt online buchen',
        ctaDescription: 'Der Termin ist kostenpflichtig',
        theme: 'dark',
      },
      [SHOW_FOLLOW_UP_BOOKING]: {
        step: 5,
        status: SHOW_FOLLOW_UP_BOOKING,
        title: 'Buchen Sie jetzt einen Folgetermin',
        description: `Folgetermine finden online statt und kosten ${follow_up_online}.`,
        ctaTitle: 'TERMIN BUCHEN',
      },
    }),
    [],
  );

  const { preassessmentDontExist, readyToBookFirstAppointment, showFollowupBooking } =
    patientStatusData;
  const eligible = patient?.eligible_for_treatment;

  const StepOne = useCallback(() => {
    let data;
    if (eligible === 'maybe') {
      data = { ...preassessmentDontExist, activeStatus: 'active' };
    } else {
      data = {
        title: 'Medizinisches Screening erfolgreich',
        activeStatus: 'completed',
        //Somebody didn't know what typing is
        step: 1,
        status: '',
        description: '',
        ctaTitle: '',
        ctaDescription: '',
        theme: '',
      };
    }
    return <Step data={data} />;
  }, [eligible]);

  const StepTwo = useCallback(() => {
    let data = readyToBookFirstAppointment as any;
    if (eligible === 'yes' && !hasPrescriptions) {
      data = {
        ...readyToBookFirstAppointment,
        activeStatus: 'active',
        firstAppointmentUrl,
        followingAppointmentUrl,
      };
    }
    return <Step data={data} />;
  }, [eligible]);

  const StepFive = useCallback(() => {
    return (
      <div className="relative overflow-hidden bg-swopa-primary-dark-blue rounded min-h-[180px]">
        <Circle className="-top-[290px] right-20" />
        <Circle className="-bottom-[300px] -right-[200px]" />
        <Circle className="-top-[320px] -left-[150px] hidden xl:block" />
        <div className="text-swopa-accent-green relative z-10">
          <div className="px-8 py-4 sm:px-16 sm:py-8 text-center flex flex-col justify-center items-center">
            <h3 className="text-swopa-primary-white text-xl">Buchen Sie jetzt einen Folgetermin</h3>
            <p className="mb-4 text-swopa-hover-grey">
              Folgetermine finden online statt und kosten <strong>{follow_up_online}.</strong>
            </p>
            <Link
              to={'/termin'}
              onClick={() => {
                setBookingUrl(followingAppointmentUrl);
              }}
            >
              <Button>{showFollowupBooking.ctaTitle}</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }, []);

  if (!patient) return null;

  if (patient.eligible_for_treatment === 'no') {
    return (
      <div className="flex flex-col items-center justify-center">
        <p className="text-swopa-primary-dark-blue text-center max-w-lg text-lg mb-5">
          Sie können leider nicht fortfahren, da Sie die folgenden Anforderungen nicht erfüllen:
        </p>
        <BulletPoints points={patient.eligible_for_treatment_reasons} />
      </div>
    );
  }

  const ctaData = eligible === 'maybe' ? preassessmentDontExist : readyToBookFirstAppointment;

  return (
    <>
      {!hasPrescriptions && eligible === 'yes' && (
        <p className="font-radikal text-swopa-primary-dark-blue sm:px-5 xl:px-0 mb-6">
          Vielen Dank für das Ausfüllen des medizinischen Screening-Fragebogens. Jetzt ist es an der
          Zeit einen Termin mit einem unserer Ärzte zu vereinbaren. Dieser wird mit Ihnen prüfen, ob
          Sie für eine medizinische Cannabisbehandlung in Frage kommen.
        </p>
      )}
      {hasPrescriptions ? (
        <StepFive />
      ) : (
        <div className="flex flex-col md:flex-row bg-white border border-swopa-border rounded p-4">
          <ul className="w-full md:w-1/2 mr-8">
            <StepOne />
            <StepTwo />
          </ul>
          {/* show on desktop */}
          <CallToAction
            data={{
              ...ctaData,
              firstAppointmentUrl,
              followingAppointmentUrl,
            }}
            status={ctaData.status}
            className="hidden md:flex"
          />
        </div>
      )}
    </>
  );
}

type BulletPointProps = {
  points: string[];
};

function BulletPoints({ points }: BulletPointProps) {
  return (
    <ul className="list-disc text-swopa-primary-dark-blue mb-5 space-y-1 ml-4">
      {points.map((point) => (
        <li key={point}>{point}</li>
      ))}
    </ul>
  );
}
