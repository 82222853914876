import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Prescriptions from './Therapy/Prescriptions';
import History from './Therapy/History';
import WelcomeModal from 'components/Therapy/WelcomeModal';
import RatingModal from 'components/Therapy/RatingModal';
import { useMemo } from 'react';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Routing for all therapy pages
 */
export default function Therapy() {
  const query = useQuery();
  const navigate = useNavigate();
  const appointmentFeedbackId = query.get('appointmentFeedbackId');
  return (
    <section className="mx-4 md:mx-[35px] xl:mx-20 mt-10">
      <WelcomeModal />
      <RatingModal
        open={appointmentFeedbackId !== null}
        onClose={() => navigate('/therapie/verschreibungen')}
        appointmentId={Number(appointmentFeedbackId)}
      />
      <Routes>
        <Route path="/verschreibungen" element={<Prescriptions />} />
        <Route path="/verlauf" element={<History />} />
        <Route path="/" element={<Navigate replace to="/therapie/verschreibungen" />} />
      </Routes>
    </section>
  );
}
