import { useState } from 'react';
import { ReactComponent as ShrinkIcon } from 'assets/icons/shrink.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/prescription_date_calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import React from 'react';
import Box from 'styles/Box';
import { format } from 'date-fns';
import classnames from 'classnames';

interface AppointmentInfo {
  location: string;
  date?: Date;
  mobile?: boolean;
  error?: boolean;
}

interface PrescriptionProps {
  title: string;
  state?: AppointmentInfo;
  steps: Array<React.ReactNode>;
  appointmentDate?: Date;
  startOpen?: boolean;
}
/**
 * Header displaying information about the appointment
 */
function PrescriptionAppointmentInfo({ location, date, mobile, error }: AppointmentInfo) {
  return (
    <div
      className={`${
        mobile ? 'flex sm:hidden justify-between mt-4' : 'hidden sm:flex space-x-10'
      }  items-center`}
    >
      {date && (
        <>
          <div className="flex space-x-2 items-center text-swopa-primary-dark-blue">
            <CalendarIcon />
            <span>{format(date, 'dd.MM.yyyy')}</span>
          </div>
          <div className="hidden sm:flex space-x-2 items-center text-swopa-primary-dark-blue">
            <ClockIcon />
            <span>{format(date, 'HH:mm')}</span>
          </div>
        </>
      )}
      <div
        className={classnames(
          `bg-swopa-hover-grey py-1 px-3 rounded text-swopa-primary-dark-blue uppercase w-32 text-center font-radikal`,
          {
            'text-swopa-primary-dark-blue bg-swopa-hover-grey': !error,
            'text-swopa-warning-red bg-swopa-warning-red/10': error,
          },
        )}
      >
        {location}
      </div>
    </div>
  );
}

/**
 * Expandable wrapper displaying all information for a single appointment with prescription and additional info
 * @param title of appointment group
 * @param state of appointment
 * @param steps to be displayed in the appointment
 * @param startOpen is a boolean whether wrapper should start open
 * @returns
 */
export default function AppointmentWrapper({
  title,
  state,
  steps,
  startOpen = true,
}: PrescriptionProps) {
  const [open, setOpen] = useState(startOpen);

  return (
    <Box>
      <div className="px-6 py-5" onClick={() => setOpen((prev) => !prev)}>
        <div className="flex justify-between items-center">
          <h2 className="text-swopa-primary-dark-blue font-radikal">{title}</h2>
          <div className="flex space-x-6 items-center">
            {state && <PrescriptionAppointmentInfo {...state} />}
            {open ? (
              <ShrinkIcon className="text-swopa-secondary-grey cursor-pointer hover:text-swopa-accent-green" />
            ) : (
              <ExpandIcon className="text-swopa-secondary-grey cursor-pointer hover:text-swopa-accent-green" />
            )}
          </div>
        </div>
        {state && <PrescriptionAppointmentInfo {...state} mobile />}
      </div>
      <div className="px-6">
        <div className={`${open ? 'h-auto' : 'h-0'} overflow-hidden transition-all`}>
          {steps.map((step, index) => (
            <div key={index}>{step}</div>
          ))}
        </div>
      </div>
    </Box>
  );
}
