import { useMemo } from 'react';
import type { CountryCode } from '../localizations/types';
import prices from '../localizations/prices';

type LocalizedPrices = {
  first_appointment_online: string;
  first_appointment_clinic: string;
  follow_up_online: string;
  no_show_fee: string;
};

export default function useLocalizedPrices(): LocalizedPrices {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;

  return useMemo((): LocalizedPrices => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return {
      first_appointment_online: prices.first_appointment_online[LOCALIZATION],
      first_appointment_clinic: prices.first_appointment_clinic[LOCALIZATION],
      follow_up_online: prices.follow_up_online[LOCALIZATION],
      no_show_fee: prices.no_show_fee[LOCALIZATION],
    };
  }, [LOCALIZATION]);
}
