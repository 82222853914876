import classNames from 'classnames';

type Step = {
  title: string;
  description: string;
};

type ProgressProps = {
  steps: Step[];
  currentStep: number;
};

const Checkmark = () => (
  <svg width="10" height="10" viewBox="0 0 12 10" fill="none">
    <path d="M1 4.99994L4.5 8.49994L10.5 1.5" strokeWidth={2} stroke="white" />
  </svg>
);

export default function Progress({ steps, currentStep }: ProgressProps) {
  const stepTexts = steps.at(currentStep);
  return (
    <div className="flex flex-col items-center">
      <ul className="flex mb-4 justify-center space-x-4">
        {steps.map((_step, index) => (
          <li
            key={index}
            className={classNames(
              'w-5 h-5 rounded-full text-xs z-10 flex items-center justify-center font-bold ',
              {
                'bg-swopa-primary-dark-blue text-white': index <= currentStep,
                'border border-swopa-primary-grey-light text-swopa-primary-grey-light bg-white ':
                  index > currentStep,
              },
            )}
          >
            {index < currentStep ? <Checkmark /> : index + 1}
          </li>
        ))}
      </ul>
      {stepTexts && (
        <div className="mb-10">
          <h1 className="text-swopa-primary-dark-blue mb-4 text-2xl md:text-3xl text-center">
            {stepTexts.title}
          </h1>
          <h2 className="text-swopa-primary-grey text-center text-sm font-normal font-sans">
            {stepTexts.description}
          </h2>
        </div>
      )}
    </div>
  );
}
