import { Field } from 'formik';
import { useState } from 'react';
import { ReactComponent as EyeOpenIcon } from 'assets/icons/eye_open.svg';
import { ReactComponent as EyeClosedIcon } from 'assets/icons/eye_closed.svg';

interface PasswordInputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: any;
  className?: string;
  label?: string;
}

export default function PasswordInput(props: PasswordInputProps) {
  const [showValue, setShowValue] = useState(false);

  return (
    <div className={`w-full ${props.className ? props.className : ''}`}>
      <label className="text-sm text-swopa-primary-dark-blue block mb-2" htmlFor={props.id}>
        {props.label}
      </label>
      <div className="relative">
        <div className="absolute right-0 top-2/4 -translate-y-2/4 mr-5 cursor-pointer">
          {showValue ? (
            <EyeOpenIcon
              className={'text-swopa-primary-dark-blue'}
              onClick={() => setShowValue(false)}
            />
          ) : (
            <EyeClosedIcon
              className={'text-swopa-secondary-grey'}
              onClick={() => setShowValue(true)}
            />
          )}
        </div>
        <Field
          id={props.id}
          name={props.name}
          type={showValue ? 'text' : 'password'}
          placeholder={props.placeholder}
          className={`appearance-none bg-[#F8F8F8] ${
            props.error
              ? 'border !border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red'
              : ' text-swopa-primary-dark-blue placeholder:text-swopa-secondary-grey'
          } rounded w-full py-3 px-4 border border-transparent focus:border focus:border-swopa-primary-dark-blue pr-12 outline-none text-base md:text-sm`}
        />
      </div>
      {props.error &&
        props.errorMessage &&
        props.errorMessage.split('\n').map((msg: string) => (
          <span key={msg} className="text-swopa-warning-red text-field-validation mt-2 block">
            {msg}
          </span>
        ))}
    </div>
  );
}
