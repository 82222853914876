import { ImagesLocalization } from './types';
import logo_ch from '../assets/images/logo_ch.png';
import logo_de from '../assets/images/logo_nav.svg';
import logo_white_de from '../assets/images/logo_white.svg';
import flag_ch from '../assets/images/flags/CH.svg';
import flag_de from '../assets/images/flags/DE.svg';

const images: ImagesLocalization = {
  logo: {
    DE: logo_de,
    CH: logo_ch,
  },
  logo_white: {
    DE: logo_white_de,
    CH: logo_ch,
  },
  flag: {
    DE: flag_de,
    CH: flag_ch,
  }
};

export default images;
