import classnames from 'classnames';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
  bgColor?: string;
  border?: string;
  onClick?: (e: any) => void;
}

export default function Box({
  children,
  className,
  bgColor = 'white',
  border = 'grey',
  onClick,
}: BoxProps) {
  return (
    <div
      {...(onClick && { onClick })}
      className={classnames(
        'rounded-[4px]',
        {
          'bg-swopa-primary-white': bgColor === 'white',
          'bg-swopa-primary-dark-blue': bgColor === 'blue',
          'border border-swopa-border': border === 'grey',
          'border border-swopa-accent-green': border === 'green',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}
