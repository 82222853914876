export default function useSessionStorage() {
  const getItem = (key: string, defaultValue: any = '') => {
    const stored = sessionStorage.getItem(key);
    if (stored) {
      return JSON.parse(stored);
    }
    return defaultValue;
  };

  const setItem = (key: string, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = (key: string) => {
    sessionStorage.removeItem(key);
  };

  const clearStorage = () => {
    sessionStorage.clear();
  };

  return {
    getItem,
    setItem,
    removeItem,
    clearStorage,
  };
}
