import { useMemo } from 'react';
import type { CountryCode, Dialog } from '../localizations/types';
import countryDialogs from 'localizations/countryDialogs';

export function useLocalizedOtherCountryDialog(): Dialog {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;

  return useMemo((): Dialog => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return {
      title: countryDialogs.other_country[LOCALIZATION].title,
      body: countryDialogs.other_country[LOCALIZATION].body,
      buttons: countryDialogs.other_country[LOCALIZATION].buttons,
    };
  }, [LOCALIZATION]);
}

export function useLocalizedIncorrectCountryDialog(): Dialog {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;

  return useMemo((): Dialog => {
    if (LOCALIZATION === undefined) {
      throw new Error('Localization not set, please specify it.');
    }

    return {
      title: countryDialogs.incorrect_country[LOCALIZATION].title,
      body: countryDialogs.incorrect_country[LOCALIZATION].body,
      buttons: countryDialogs.incorrect_country[LOCALIZATION].buttons,
    };
  }, [LOCALIZATION]);
}
