import { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';

interface IToggle {
  name?: string;
  startState?: boolean;
  onChange?: (state: boolean) => void;
}

export default function Toggle({ name, onChange, startState = false }: IToggle) {
  const [enabled, setEnabled] = useState(startState);

  useEffect(() => {
    if (onChange) {
      onChange(enabled);
    }
  }, [enabled, onChange]);

  return (
    <Switch
      name={name}
      checked={enabled}
      onChange={setEnabled}
      className={`transform transition ease-in-out duration-200 ${
        enabled ? 'bg-swopa-accent-green' : 'bg-swopa-passive'
      } relative inline-flex h-4 w-7 items-center rounded-full`}
    >
      <span
        className={`transition ease-in-out duration-200 ${
          enabled ? 'translate-x-[14px]' : 'translate-x-1'
        } inline-block h-[10px] w-[10px] transform rounded-full bg-white`}
      />
    </Switch>
  );
}
