import Document from 'components/Documents/Document';
import { readFileAsync } from 'components/util/files';
import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import Button from 'styles/Button';
import DocumentUpload from './DocumentUpload';
import DocumentUploadScreen from './DocumentUploadScreen';
import { FileWithPath } from 'react-dropzone';
import useDocuments from './useDocuments';
import { useAuthContext } from 'components/util/useAuth';
import http from 'components/util/http';
import { useQueryClient } from '@tanstack/react-query';
import { fromEvent } from 'file-selector';
import { useNotificationContext } from 'components/Notification';

interface UploadFileButtonProps {
  onOpen: () => void;
  onUpload: (files: Array<FileWithPath>) => void | Promise<void>;
}

function UploadFileButton({ onOpen, onUpload }: UploadFileButtonProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { showNotification } = useNotificationContext();

  // this state key is used to re-mount the input element
  // without this the input will keep it's value and selecting
  // the same file twice after another won't call the onChange a second time
  // this happens for example after uploading and deleting and then trying to upload the same file again
  const [inputKey, setInputKey] = useState(1);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const valid = [
        'text/xml',
        'application/vnd.apple.pages',
        'text/xml-external-parsed-entity',
        'application/pdf',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png',
        'image/webp',
      ].find((x) => x == file.type);

      if (valid) {
        onUpload((await fromEvent(e.nativeEvent)) as Array<FileWithPath>);
      } else {
        showNotification(
          { type: 'error', text: ` Nicht unterstützter Dateityp: ${file.type}.` },
          5000,
        );
      }

      setInputKey((k) => k + 1);
    }
  };

  return (
    <>
      <input
        type={'file'}
        key={inputKey}
        accept="text/xml, application/vnd.apple.pages, text/xml-external-parsed-entity, application/pdf, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, image/webp"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={onChange}
      />
      <Button
        size="small"
        onClick={() => {
          onOpen();
          inputRef.current && inputRef.current.click();
        }}
      >
        Hochladen
      </Button>
    </>
  );
}

function useShowUploadOverlayState(resetSignal: any) {
  // any child element fires a dragenter and dragleave when dragging a file around
  // counting drag events is an easy way to make sure to not make the screen "flicker"
  const [dragging, setDragging] = useState(0);
  const onDragEnter = useCallback(() => setDragging((p) => p + 1), []);
  const onDragLeave = useCallback(() => setDragging((p) => p - 1), []);

  useEffect(() => setDragging(0), [resetSignal]);

  useEffect(() => {
    document.body.addEventListener('dragenter', onDragEnter, true);
    document.body.addEventListener('dragleave', onDragLeave, true);
    return () => {
      document.body.removeEventListener('dragenter', onDragEnter, true);
      document.body.removeEventListener('dragleave', onDragLeave, true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return dragging !== 0;
}

export default function MyDocuments() {
  const queryClient = useQueryClient();
  const [uploadableFiles, setUploadableFiles] = useState<
    Array<{ filename: string; asset: string }> | undefined
  >(undefined);
  const { showNotification, clearNotification } = useNotificationContext();
  const { authenticatedUser } = useAuthContext();
  const [sort] = useState('date');
  const { data: documents, refetch: refetchDocuments } = useDocuments();
  const showUpload = useShowUploadOverlayState(uploadableFiles); // || true;

  useEffect(() => {
    return () => clearNotification();
  }, [clearNotification]);

  const handleDocumentUpload = useCallback(
    async (files: Array<FileWithPath>) => {
      const TYPES = [
        'text/xml',
        'application/vnd.apple.pages',
        'text/xml-external-parsed-entity',
        'application/pdf',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png',
        'image/webp',
      ];
      for (let i = 0; i < files.length; ++i) {
        const filesize = (files[i].size / 1024 / 1024).toFixed(4);
        if (Number(filesize) > 20) {
          showNotification(
            {
              text: 'Ihre Datei ist leider größer als 20MB. Bitte versuchen Sie es erneut',
              type: 'error',
            },
            5000,
          );
          return;
        }
        if (!TYPES.find((type) => type === files[i].type)) {
          showNotification(
            {
              text: 'Das Dateiformat wird leider nicht unterstützt.',
              type: 'error',
            },
            5000,
          );
          return;
        }
      }
      const assets = await Promise.all(files.map((file) => readFileAsync(file)));
      setUploadableFiles(
        files.map(({ name }, index) => ({ filename: name, asset: assets[index] + '' })),
      );
    },
    [showNotification, setUploadableFiles],
  );

  const handleDeleteDocument = useCallback(
    async (id: number) => {
      await http.delete(`/medical/documents/${id}/`, {});
      queryClient.invalidateQueries({
        queryKey: ['documents'],
      });
    },
    [queryClient],
  );

  const documentData = useMemo(() => {
    if (!documents) return [];
    const res = documents.map(({ name, asset, uploaded_at, uploaded_by, id, content_type }) => {
      const filetype = asset.substring(asset.lastIndexOf('.') + 1, asset.indexOf('?'));
      const uploadedBy: 'self' | 'doctor' =
        uploaded_by === authenticatedUser?.id ? 'self' : 'doctor';
      return {
        filename: name,
        filetype,
        uploadDate: new Date(uploaded_at),
        downloadLink: asset,
        uploadedBy,
        deleteCallback: () => handleDeleteDocument(id),
        documentId: id,
        category: content_type,
      };
    });
    if (sort === 'date') {
      return res.sort(({ uploadDate: a }, { uploadDate: b }) => b.getTime() - a.getTime());
    } else {
      return res.sort(({ filetype: a }, { filetype: b }) => b.localeCompare(a));
    }
  }, [documents, authenticatedUser, sort, handleDeleteDocument]);

  return (
    <>
      <div className="flex justify-end mb-6 items-center">
        <div className="flex space-x-8 items-center ">
          <UploadFileButton onOpen={() => setUploadableFiles([])} onUpload={handleDocumentUpload} />
        </div>
      </div>
      <div className="grid space-y-2">
        {uploadableFiles && (
          <>
            {uploadableFiles.map((val) => (
              <DocumentUpload
                {...val}
                key={val.filename}
                onCancel={() =>
                  setUploadableFiles((prev) =>
                    prev ? prev.filter((x) => x.filename !== val.filename) : prev,
                  )
                }
                onComplete={() =>
                  refetchDocuments().then(() =>
                    setUploadableFiles((prev) =>
                      prev ? prev.filter((x) => x.filename !== val.filename) : prev,
                    ),
                  )
                }
              />
            ))}
          </>
        )}
        {documentData.map((vals, index) => (
          <Document {...vals} key={index} />
        ))}
      </div>
      {/* @ts-ignore */}
      {showUpload && <DocumentUploadScreen onDrop={handleDocumentUpload} />}
    </>
  );
}
