import { useState } from 'react';
import { object, string } from 'yup';
import { Form, Formik } from 'formik';
import Button from 'styles/Button';
import { useNotificationContext } from 'components/Notification';
import PasswordInput from 'styles/FormikPasswordInput';
import { useAuthContext } from 'components/util/useAuth';
import {
  PasswordRepeatSubForm,
  PasswordRepeatSubFormFields,
  validationSchema as passwordValidationSchema,
  defaultValues as passwordDefaultValues,
} from 'components/subforms/PasswordRepeat';

type ChangePasswordFormValues = {
  currentPassword: string;
} & PasswordRepeatSubFormFields;

const initialValues: ChangePasswordFormValues = {
  currentPassword: '',
  ...passwordDefaultValues,
};

const PASSWORD_REQUIRED_ERROR = 'Bitte geben Sie ein Passwort ein.';

const validatonSchema = object({
  currentPassword: string().required(PASSWORD_REQUIRED_ERROR),
}).concat(passwordValidationSchema);

export default function ChangePasswordForm() {
  const { showNotification } = useNotificationContext();
  const [showForm, setShowForm] = useState(false);

  if (showForm) {
    return (
      <InnerForm
        onSuccess={() => {
          setShowForm(false);
          showNotification(
            { type: 'success', text: 'Ihr neues Passwort wurde erfolgreich gespeichert.' },
            10000,
          );
        }}
      />
    );
  }

  return <Button onClick={() => setShowForm(true)}>PASSWORT ÄNDERN</Button>;
}

interface InnerFormProps {
  onSuccess: () => void;
}

function InnerForm({ onSuccess }: InnerFormProps) {
  const { updateUser } = useAuthContext();
  const { showNotification, clearNotification } = useNotificationContext();
  return (
    <Formik<ChangePasswordFormValues>
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={validatonSchema}
      onSubmit={(formData, { setErrors, setSubmitting }) => {
        setSubmitting(true);
        clearNotification();

        updateUser({
          password: formData.password,
          old_password: formData.currentPassword,
        })
          .then(onSuccess)
          .catch((err) => {
            if (err.old_password) {
              setErrors({
                currentPassword: 'Passwort inkorrekt',
              });
            } else {
              showNotification({ type: 'error', text: 'Etwas ist schiefgelaufen.' });
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <PasswordInput
            id="currentPassword"
            name="currentPassword"
            placeholder="Aktuelles Passwort"
            error={!!(formikProps.touched.currentPassword && formikProps.errors.currentPassword)}
            errorMessage={formikProps.errors.currentPassword}
            className="mb-6"
          />
          <PasswordRepeatSubForm />
          <Button type="submit" disabled={formikProps.isSubmitting}>
            PASSWORT SPEICHERN
          </Button>
        </Form>
      )}
    </Formik>
  );
}
