const de = {
  consentModal: {
    label: 'Einwilligung zu Cookies',
    title: 'Cookies',
    description:
      'Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um unsere Website zu verbessern und Ihnen persönlichere Dienste zu bieten, sowohl auf dieser Website als auch über andere Medien. Weitere Informationen über die von uns verwendeten Cookies finden Sie in unserer Datenschutzrichtlinie.',
    acceptAllBtn: 'Alle akzeptieren',
    acceptNecessaryBtn: 'Alle ablehnen',
    showPreferencesBtn: 'Präferenzen',
    footer: `<a href="${process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL}/datenschutz">Datenschutz</a>\n<a href="${process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL}/agb">AGB</a>`,
  },
  preferencesModal: {
    title: 'Cookie-Einstellungen',
    acceptAllBtn: 'Alle akzeptieren',
    acceptNecessaryBtn: 'Alle ablehnen',
    savePreferencesBtn: 'Einstellungen speichern',
    closeIconLabel: 'Schließen',
    serviceCounterLabel: 'Dienst|Dienste',
    sections: [
      {
        title: 'Präferenzen für die Zustimmung verwalten',
        description: `TDiese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um unsere Website zu verbessern und Ihnen persönlichere Dienste zu bieten, sowohl auf dieser Website als auch über andere Medien. Weitere Informationen über die von uns verwendeten Cookies finden Sie in unserer <a class="cc__link" href="${process.env.REACT_APP_MARKETING_WEBSITE_BASE_URL}/datenschutz">Datenschutzrichtlinie</a>.`,
      },
      {
        title: 'Streng notwendige Cookies <span class="pm__badge">Immer aktiviert</span>',
        description:
          'Diese Cookies sind unerlässlich, damit Sie die Website durchsuchen und ihre Funktionen nutzen können. In der Regel werden sie nur als Reaktion auf Aktionen gesetzt, die Sie im Zusammenhang mit der Inanspruchnahme von Diensten durchführen, wie z. B. die Einstellung Ihrer Datenschutzpräferenzen oder das Ausfüllen von Formularen. Sie können Ihren Browser so einstellen, dass er diese Cookies blockiert oder Sie darauf hinweist, aber einige Teile der Website werden dann nicht funktionieren. Diese Cookies speichern keine persönlich identifizierbaren Informationen.',
        linkedCategory: 'necessary',
      },
      {
        title: 'Analytische Cookies',
        description:
          'Diese Cookies sammeln Informationen darüber, wie Sie eine Webseite nutzen, welche Seiten Sie besucht und auf welche Links Sie geklickt haben. Keine dieser Informationen kann verwendet werden, um Sie zu identifizieren. Sie werden alle zusammengefasst und sind daher anonymisiert.',
        linkedCategory: 'analytics',
      },
      {
        title: 'User experience cookies',
        description:
          'Diese Tracker helfen uns, die Qualität Ihrer Nutzererfahrung zu verbessern und Interaktionen mit externen Inhalten, Netzwerken und Plattformen zu ermöglichen.',
        linkedCategory: 'personalization',
      },
      {
        title: 'Marketing-Cookies',
        description:
          'Diese Cookies verfolgen Ihre Online-Aktivitäten, um uns zu helfen, relevantere Werbebotschaften zu liefern. Sie können von Dritten verwendet werden, um auf der Grundlage von Browsing-Informationen ein Profil Ihrer Interessen zu erstellen, indem Ihr Browser und Ihr Endgerät identifiziert werden.',
        linkedCategory: 'marketing',
      },
      {
        title: 'Weitere Informationen',
        description:
          'Wenn Sie Fragen zu unserer Cookies-Politik und Ihre Wahlmöglichkeiten haben, können Sie uns gerne kontaktieren.',
      },
    ],
  },
};

export default de;
